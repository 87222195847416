const state = {
  progress: 0,
}

const actions = {
  setProgress({ commit }, val) {
    commit('SET_PROGRESS', val)
  },

  reset({ commit }) {
    commit('RESET')
  },
}

const mutations = {
  SET_PROGRESS(state, val) {
    state.progress = val
  },

  RESET(state) {
    state.progress = 0
  },
}

export const uploadProgress = {
  namespaced: true,
  state,
  actions,
  mutations,
}
