import { getDeleteMessage, getChangeMessage, getAddMessage, getRemoveMessage } from '@/spaday/helpers'
import { ClientService } from '@/services'

const state = {
  all: {},
  object: {},
  delete: {},
  combobox: {},
  users: {},
}

const MODEL_NAME = 'client'

const actions = {
  getAll({ commit }, params) {
    commit('GET_ALL_REQUEST')

    return ClientService.getAll(params).then(
      (data) => {
        commit('GET_ALL_SUCCESS', data)
        return data
      },
      (error) => {
        commit('GET_ALL_FAILURE', error)
      },
    )
  },

  get({ commit }, id) {
    commit('GET_REQUEST')

    return ClientService.get(id).then(
      (data) => {
        commit('GET_SUCCESS', data)
      },
      (error) => {
        commit('GET_FAILURE', error)
      },
    )
  },

  create({ dispatch, commit }, data) {
    commit('CREATE_REQUEST')

    return ClientService.create(data).then(
      (data) => {
        commit('CREATE_SUCCESS', data)
        // show toast dialog
        let ctx = {
          text: getAddMessage(MODEL_NAME, `${data.name}`),
          color: 'success',
        }
        dispatch('toastDialog/show', ctx, { root: true })
      },
      (error) => {
        commit('CREATE_FAILURE', error)
      },
    )
  },

  update({ dispatch, commit }, { id, data }) {
    commit('UPDATE_REQUEST')

    return ClientService.update(id, data).then(
      (data) => {
        commit('UPDATE_SUCCESS', data)
        // show toast dialog
        let ctx = {
          text: getChangeMessage(MODEL_NAME, `${data.name}`),
          color: 'success',
        }
        dispatch('toastDialog/show', ctx, { root: true })
      },
      (error) => {
        commit('UPDATE_FAILURE', error)
      },
    )
  },

  preDelete({ dispatch, commit }, object) {
    commit('PRE_DELETE_REQUEST')

    return ClientService.preDelete(object.pk).then(
      (data) => {
        commit('PRE_DELETE_SUCCESS')
        // show delete confirmation dialog
        data.pk = object.pk
        data.name = `${object.name}`
        dispatch('deleteDialog/confirm', data, { root: true })
      },
      (error) => {
        commit('PRE_DELETE_FAILURE', error)
      },
    )
  },

  delete({ dispatch, commit }, object) {
    commit('DELETE_REQUEST')

    return ClientService.delete(object.pk).then(
      () => {
        commit('DELETE_SUCCESS', object.pk)
        // show toast dialog
        let ctx = {
          text: getDeleteMessage(MODEL_NAME, `${object.name}`),
          color: 'success',
        }
        dispatch('toastDialog/show', ctx, { root: true })
      },
      (error) => {
        commit('DELETE_FAILURE', error)
      },
    )
  },

  clear({ commit }) {
    commit('CLEAR')
  },

  getCombobox({ commit }) {
    commit('GET_COMBOBOX_REQUEST')

    return ClientService.combobox().then(
      (data) => commit('GET_COMBOBOX_SUCCESS', data),
      (error) => commit('GET_COMBOBOX_FAILURE', error),
    )
  },

  getUsers({ commit }, id) {
    commit('GET_USERS_REQUEST')

    return ClientService.users(id).then(
      (data) => commit('GET_USERS_SUCCESS', data),
      (error) => commit('GET_USERS_FAILURE', error),
    )
  },

  addUsers({ dispatch, commit }, { id, data }) {
    commit('ADD_USERS_REQUEST')

    return ClientService.addUsers(id, data).then(
      (data) => {
        commit('ADD_USERS_SUCCESS', data)
        // show toast dialog
        let ctx = {
          text: getAddMessage('user'),
          color: 'success',
        }
        dispatch('toastDialog/show', ctx, { root: true })
      },
      (error) => {
        commit('ADD_USERS_FAILURE', error)
      },
    )
  },

  removeUsers({ dispatch, commit }, { id, data }) {
    commit('REMOVE_USERS_REQUEST')

    return ClientService.removeUsers(id, data).then(
      (data) => {
        commit('REMOVE_USERS_SUCCESS', data)
        // show toast dialog
        let ctx = {
          text: getRemoveMessage('user'),
          color: 'success',
        }
        dispatch('toastDialog/show', ctx, { root: true })
      },
      (error) => {
        commit('REMOVE_USERS_FAILURE', error)
      },
    )
  },
}

const mutations = {
  GET_ALL_REQUEST(state) {
    state.all = {
      loading: true,
    }
  },

  GET_ALL_SUCCESS(state, data) {
    state.all = {
      items: data.results,
    }
  },

  GET_ALL_FAILURE(state, error) {
    state.all = {
      error,
    }
  },

  GET_REQUEST(state) {
    state.object = {
      loading: true,
    }
  },

  GET_SUCCESS(state, data) {
    state.object = data
  },

  GET_FAILURE(state, error) {
    state.object = {
      error,
    }
  },

  CREATE_REQUEST(state) {
    state.object = {
      loading: true,
    }
  },

  CREATE_SUCCESS(state, data) {
    state.object = data
  },

  CREATE_FAILURE(state, error) {
    state.object = {
      error,
    }
  },

  UPDATE_REQUEST(state) {
    state.object = {
      loading: true,
    }
  },

  UPDATE_SUCCESS(state, data) {
    state.object = data
  },

  UPDATE_FAILURE(state, error) {
    state.object = {
      error,
    }
  },

  CLEAR(state) {
    state.object = {}
  },

  PRE_DELETE_REQUEST(state) {
    state.delete = {
      loading: true,
    }
  },

  PRE_DELETE_SUCCESS(state) {
    state.delete = {}
  },

  PRE_DELETE_FAILURE(state, error) {
    state.delete = {
      error,
    }
  },

  DELETE_REQUEST(state) {
    state.delete = {
      loading: true,
    }
  },

  DELETE_SUCCESS(state, id) {
    state.delete = {}

    // remove deleted object from state
    if (state.all.items) {
      state.all.items = state.all.items.filter((object) => object.pk !== id)
    }
  },

  DELETE_FAILURE(state, error) {
    state.delete = {
      error,
    }
  },

  /** Combobox **/
  GET_COMBOBOX_REQUEST(state) {
    state.combobox = {
      loading: true,
    }
  },
  GET_COMBOBOX_SUCCESS(state, data) {
    state.combobox = {
      items: data,
    }
  },
  GET_COMBOBOX_FAILURE(state, error) {
    state.combobox = {
      error,
    }
  },

  /** Client Users **/
  GET_USERS_REQUEST(state) {
    state.users = {
      loading: true,
    }
  },

  GET_USERS_SUCCESS(state, data) {
    state.users = {
      items: data,
    }
  },

  GET_USERS_FAILURE(state, error) {
    state.users = {
      error,
    }
  },

  /** Add/Remove Users **/
  ADD_USERS_REQUEST() {},

  ADD_USERS_SUCCESS() {},

  ADD_USERS_FAILURE() {},

  REMOVE_USERS_REQUEST() {},

  REMOVE_USERS_SUCCESS(state, data) {
    // remove deleted objects from state
    state.users.items = state.users.items.filter((object) => data.users.includes(object.pk))
  },

  REMOVE_USERS_FAILURE() {},
}

export const clients = {
  namespaced: true,
  state,
  actions,
  mutations,
}
