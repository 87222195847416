<template>
  <div>
    <FormHeader
      title="Update Device"
      :subtitle="object.name ? object.name : object.pk"
      btnLabel="Update Device"
      :canChange="hasPermission('core.change_device')"
      :canDelete="hasPermission('core.delete_device')"
      @submit="submit"
      @delete="preDeleteDevice(object)"
      :deleteLoading="$store.state.devices.delete.loading"
      :saveLoading="$store.state.devices.object.loading"
    />

    <v-form ref="form" :disabled="object.loading" :readonly="!hasPermission('core.change_device')">
      <v-card class="mb-4" :loading="object.loading">
        <v-toolbar dark flat dense color="primary">
          <v-icon left>mdi-information-variant</v-icon>
          <v-toolbar-title> General Info </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field v-model="object.name" label="Name"></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field v-model="object.group" label="Group"></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <!-- <v-text-field v-model="object.client" label="Client"></v-text-field> -->
              <v-select
                v-model="object.client"
                label="Client"
                :items="clients.items"
                :loading="clients.loading"
                item-text="text"
                item-value="value"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field :value="object.owner" label="Owner" readonly disabled></v-text-field>
            </v-col>
            <!-- <v-col cols="12" md="3">
              <v-checkbox
                v-model="object.published"
                label="Published"
                hint="When published, this Device will appear in the Agent list."
                persistent-hint
              ></v-checkbox>
            </v-col> -->
          </v-row>
        </v-card-text>

        <CardSubheader icon="mdi-tag-multiple" title="Tags" />
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-autocomplete
                label="Tags"
                v-model="object.tags"
                :items="tags.items"
                :loading="tags.loading"
                item-value="pk"
                :item-text="tagDisplay"
                multiple
                clearable
                small-chips
                deletable-chips
                hide-no-data
                persistent-hint
                hint="The tags associated with this device."
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>

        <CardSubheader icon="mdi-cellphone-link" title="Device Info" />
        <v-form readonly disabled>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field :value="object.hardware_id" label="Hardware ID"></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field :value="object.platform" label="Platform"></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field :value="object.os" label="Operating System"></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field :value="object.cpu" label="CPU"></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field :value="object.arch" label="Architecture"></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field :value="object.manufacturer" label="Manufacturer"></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field :value="object.model" label="Model"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-form>

        <v-card-actions
          ><v-spacer></v-spacer>
          <div class="caption">
            Device ID <strong>{{ object.pk }}</strong>
          </div></v-card-actions
        >
      </v-card>
    </v-form>

    <DeleteConfirmation @confirm-delete="deleteRedirect" />
  </div>
</template>

<script>
import { hasPermission } from '@/spaday/helpers'
import { mapState, mapActions } from 'vuex'
import { FormHeader, DeleteConfirmation, CardSubheader } from '@/spaday/components'

export default {
  components: {
    DeleteConfirmation,
    FormHeader,
    CardSubheader,
  },
  data: () => ({
    rules: {
      required: (value) => !!value || 'Required.',
      min: (v) => v.length >= 8 || 'At least 8 characters',
    },
  }),
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      clients: (state) => state.clients.combobox,
      object: (state) => state.devices.object,
      tags: (state) => state.tags.combobox,
      // operatingSystems: (state) => state.operatingsystems.combobox,
    }),
    tagDisplay() {
      // return this.user.is_superuser ? 'name' : 'display_name'
      return 'display_name'
    },
  },
  mounted() {
    this.getDevice(this.$route.params.pk)
    // this.getOperatingSystems()
    this.getClients()
    this.getTags()
  },
  beforeDestroy() {
    this.clearDevice()
  },
  methods: {
    hasPermission,
    ...mapActions('tags', {
      getTags: 'getCombobox',
    }),
    // ...mapActions('operatingsystems', {
    //   getOperatingSystems: 'getCombobox',
    // }),
    ...mapActions('devices', {
      getDevice: 'get',
      updateDevice: 'update',
      preDeleteDevice: 'preDelete',
      deleteDevice: 'delete',
      clearDevice: 'clear',
    }),
    ...mapActions('clients', {
      getClients: 'getCombobox',
    }),
    submit() {
      if (!this.$refs.form.validate()) return

      this.updateDevice({ id: this.object.pk, data: this.object }).then(() => {
        this.$router.go(-1)
      })
    },
    deleteRedirect(object) {
      this.deleteDevice(object).then(() => {
        this.$router.go(-1)
      })
    },
  },
}
</script>
