import { List, Update, Create } from '@/spaday/views/user'

const routes = [
  {
    path: '/users',
    name: 'User',
    component: List,
    meta: {
      public: false,
      permissions: ['users.view_user'],
    },
  },
  {
    path: '/users/add',
    name: 'AddUser',
    component: Create,
    meta: {
      public: false,
      permissions: ['users.add_user'],
    },
  },
  {
    path: '/users/:pk/change',
    name: 'ChangeUser',
    component: Update,
    meta: {
      public: false,
      permissions: ['users.change_user'],
    },
  },
]

export default routes

// export default routes.map((route) => {
//   const meta = {
//     public: false,
//   }
//   return {
//     ...route,
//     meta,
//   }
// })
