import http from '@/spaday/http-common'

class GroupService {
  /** Base CRUD REST calls **/
  getAll(params = {}) {
    return http.get('/groups/', { params })
  }

  get(pk) {
    return http.get(`/groups/${pk}/`)
  }

  create(data) {
    return http.post('/groups/', data)
  }

  update(pk, data) {
    return http.put(`/groups/${pk}/`, data)
  }

  preDelete(pk) {
    return http.get(`/groups/${pk}/confirm-delete/`)
  }

  delete(pk) {
    return http.delete(`/groups/${pk}/`)
  }

  /** Specialized REST calls **/

  combobox() {
    return http.get(`/groups/combobox/`)
  }

  users(pk) {
    return http.get(`/groups/${pk}/users/`)
  }

  addUsers(pk, ids) {
    return http.post(`/groups/${pk}/add-users/`, {
      users: ids,
    })
  }

  removeUsers(pk, ids) {
    return http.post(`/groups/${pk}/remove-users/`, {
      users: ids,
    })
  }
}

export default new GroupService()
