import http from '@/spaday/http-common'

class UserService {
  /** Base CRUD REST calls **/
  getAll(params = {}) {
    return http.get('/users/', { params })
  }

  get(pk) {
    return http.get(`/users/${pk}/`)
  }

  create(data) {
    return http.post('/users/', data)
  }

  update(pk, data) {
    return http.put(`/users/${pk}/`, data)
  }

  preDelete(pk) {
    return http.get(`/users/${pk}/confirm-delete/`)
  }

  delete(pk) {
    return http.delete(`/users/${pk}/`)
  }

  /** Specialized REST calls **/

  changePassword(pk, data) {
    return http.put(`/users/${pk}/password/`, data)
  }

  recentLogins() {
    return http.get(`/users/recent-logins/`)
  }
}

export default new UserService()
