<template>
  <v-navigation-drawer app permanent dark v-model="drawer" :mini-variant.sync="mini" clipped width="310">
    <template v-slot:prepend>
      <v-list-item two-line class="px-2">
        <v-list-item-avatar color="secondary">
          <span v-if="user.initials">{{ user.initials }}</span>
          <v-icon v-else>mdi-account</v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>{{ user.first_name }} {{ user.last_name }}</v-list-item-title>
          <v-list-item-subtitle v-if="user.is_superuser">Superuser</v-list-item-subtitle>
        </v-list-item-content>
        <v-btn icon @click.stop="mini = !mini">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>
    </template>

    <v-divider></v-divider>

    <v-list dense nav>
      <template v-for="(item, index) in items">
        <v-list-item
          v-if="!item.items && item.icon && hasAnyPermission(item.meta.permissions)"
          :to="item.to"
          :key="item.title"
        >
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group
          v-else-if="item.items"
          :key="item.title"
          v-model="item.active"
          :prepend-icon="item.icon"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item v-for="child in item.items" :key="child.title" link :to="child.to">
            <v-list-item-content>
              <v-list-item-title
                >{{ child.title }} <v-chip v-if="child.chip" x-small style="float: right">0</v-chip></v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-divider v-else-if="item.divider" :key="index" class="my-2"></v-divider>

        <v-subheader v-else-if="item.header && !mini" :key="`${item.header}${index}`">
          {{ item.header }}
        </v-subheader>
      </template>
    </v-list>

    <template v-slot:append> </template>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from 'vuex'
import { hasAnyPermission } from '@/spaday/helpers'

export default {
  props: {
    items: { type: Array },
  },
  data: () => ({
    drawer: true,
    mini: true,
  }),
  computed: {
    ...mapState('account', ['status', 'user']),
  },
  methods: {
    hasAnyPermission,
  },
}
</script>
