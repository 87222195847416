import Login from '@/spaday/views/auth/Login.vue'
import Register from '@/spaday/views/auth/Register.vue'

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
  },
]

export default routes.map((route) => {
  const meta = {
    public: true,
    onlyLoggedOut: true,
  }
  return {
    ...route,
    meta,
  }
})
