// ----------------------------------------------------------------------------
// CRUD Messages
// ----------------------------------------------------------------------------

export const getAddMessage = (model, name = null) => {
  if (name) {
    return `The ${model} <strong>"${name}"</strong> was added successfully.`
  }
  return `The ${model} was added successfully.`
}

export const getChangeMessage = (model, name = null) => {
  if (name) {
    return `The ${model} <strong>"${name}"</strong> was changed successfully.`
  }
  return `The ${model} was changed successfully.`
}

export const getDeleteMessage = (model, name = null) => {
  if (name) {
    return `The ${model} <strong>"${name}"</strong> was deleted successfully.`
  }
  return `The ${model} was deleted successfully.`
}

export const getRemoveMessage = (model, name = null) => {
  if (name) {
    return `The ${model} <strong>"${name}"</strong> was removed successfully.`
  }
  return `The ${model} was removed successfully.`
}
