//import store from '@/store'

/**
 * Check if a user has permission.
 *
 * @param {String} codename
 * @returns Boolean
 */
export const hasPermission = (codename) => {
  const user = JSON.parse(sessionStorage.getItem('user'))
  if (user.is_superuser) return true
  return user.permissions_codenames.includes(codename)
}

/**
 * Check if user has any of the permissions given.
 *
 * @param {Array} codenames
 * @returns Boolean
 */
export const hasAnyPermission = (codenames) => {
  const user = JSON.parse(sessionStorage.getItem('user'))
  if (user.is_superuser || codenames.length === 0) return true
  for (const codename of codenames) {
    if (hasPermission(codename)) return true
  }
  return false
}
