<template>
  <span>{{ formatted }}</span>
</template>

<script>
import gsap from 'gsap'
import { Power2 } from 'gsap'

/**
 * A Vue component that displays a value and animates changes.
 *
 * Accepts props for the value; the animation duration; and a method to format the number, e.g. using Intl.NumberFormat.
 */
export default {
  name: 'AnimatedInteger',
  props: {
    value: { default: 0 },
    duration: { default: 1 },
    formatter: { type: Function, default: (n) => n },
  },

  data() {
    return {
      displayValue: Math.ceil(this.value),
      tweenValue: this.value,
    }
  },

  computed: {
    formatted() {
      return this.formatter(this.displayValue)
    },
  },

  watch: {
    value() {
      gsap.to(this, {
        ease: Power2.easeInOut,
        tweenValue: Math.ceil(this.value),
        duration: this.duration,
        onUpdate: () => {
          this.displayValue = Math.round(this.tweenValue)
        },
        onComplete: () => {
          this.displayValue = Math.round(this.value)
        },
      })
    },
  },
}
</script>
