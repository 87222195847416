import http from '@/spaday/http-common'

class DeviceService {
  /** Base CRUD REST calls **/
  getAll(params = {}) {
    return http.get('/devices/', { params })
  }

  get(id) {
    return http.get(`/devices/${id}/`)
  }

  create(data) {
    return http.post('/devices/', data)
  }

  update(pk, data) {
    return http.put(`/devices/${pk}/`, data)
  }

  preDelete(pk) {
    return http.get(`/devices/${pk}/confirm-delete/`)
  }

  delete(pk) {
    return http.delete(`/devices/${pk}/`)
  }

  /** Specialized REST calls **/

  //   combobox() {
  //     return http.get(`/interactive/combobox/`)
  //   }

  getToken() {
    return http.get(`/devices/token/`)
  }
}

export default new DeviceService()
