<template>
  <v-card class="mb-4" color="transparent">
    <v-card-text>
      <v-row align="center" justify="center">
        <v-col class="grow">
          <v-card-title class="text--primary">{{ title }}</v-card-title>
          <v-card-subtitle>{{ subtitle }}</v-card-subtitle>
        </v-col>
        <v-col class="shrink" v-if="canAdd">
          <v-btn x-large color="primary" :to="route"><v-icon left> mdi-plus </v-icon>{{ btnLabel }}</v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    title: { type: String },
    subtitle: { type: String },
    btnLabel: { type: String },
    route: { type: Object },

    canAdd: { type: Boolean, default: false },
    canChange: { type: Boolean, default: false },
    canDelete: { type: Boolean, default: false },
  },
}
</script>
