import http from '@/spaday/http-common'

class ClientService {
  /** Base CRUD REST calls **/
  getAll(params = {}) {
    return http.get('/clients/', { params })
  }

  get(pk) {
    return http.get(`/clients/${pk}/`)
  }

  create(data) {
    return http.post('/clients/', data)
  }

  update(pk, data) {
    return http.put(`/clients/${pk}/`, data)
  }

  preDelete(pk) {
    return http.get(`/clients/${pk}/confirm-delete/`)
  }

  delete(pk) {
    return http.delete(`/clients/${pk}/`)
  }

  /** Specialized REST calls **/

  combobox() {
    return http.get(`/clients/combobox/`)
  }

  users(pk) {
    return http.get(`/clients/${pk}/users/`)
  }

  addUsers(pk, ids) {
    return http.post(`/clients/${pk}/add-users/`, {
      users: ids,
    })
  }

  removeUsers(pk, ids) {
    return http.post(`/clients/${pk}/remove-users/`, {
      users: ids,
    })
  }
}

export default new ClientService()
