<template>
  <div>
    <FormHeader
      title="View Task Result"
      :subtitle="object.task_id"
      :canDelete="hasPermission('django_celery_results.delete_taskresult')"
      @delete="preDeleteTaskResult(object)"
      :deleteLoading="$store.state.taskresults.delete.loading"
    >
      <template v-slot:subtitle-prepend>
        <v-chip small :color="statusColor">{{ object.status }}</v-chip>
        <!-- <v-chip small>{{ object.model }}</v-chip> -->
      </template></FormHeader
    >

    <v-form ref="form" :disabled="object.loading" :readonly="true">
      <v-card class="mb-4" :loading="object.loading">
        <v-card-text>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                :value="object.task_id"
                label="Task ID"
                hint="Celery ID for the Task that was run"
                persistent-hint
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                :value="object.task_name"
                label="Task Name"
                hint="Name of the Task which was run"
                persistent-hint
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                :value="object.status"
                label="Task State"
                hint="Current state of the task being run"
                persistent-hint
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                :value="object.worker"
                label="Worker"
                hint="Worker that executes the task"
                persistent-hint
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                :value="object.content_type"
                label="Result Content Type"
                hint="Content type of the result data"
                persistent-hint
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                :value="object.content_encoding"
                label="Result Encoding"
                hint="The encoding used to save the task result data"
                persistent-hint
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <CardSubheader icon="mdi-variable" title="Parameters" />
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                :value="object.task_args"
                label="Task Positional Arguments"
                hint="JSON representation of the positional arguments used with the task"
                persistent-hint
              ></v-text-field>
            </v-col>
            <v-col v-if="object.task_kwargs !== '{}'" cols="12">
              <v-text-field
                :value="object.task_kwargs"
                label="Task Named Arguments"
                hint="JSON representation of the named arguments used with the task"
                persistent-hint
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <CardSubheader icon="mdi-clipboard-text-clock-outline" title="Result" />
        <v-card-text>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                :value="`${localDatetime(object.date_done)}`"
                label="Completed DateTime"
                hint="Datetime when the task was completed"
                persistent-hint
              ></v-text-field>
            </v-col>

            <v-col v-if="object.result !== 'null'" cols="12" md="12">
              <v-text-field
                :value="object.result"
                label="Task Result"
                hint="The data returned by the task. Use content_encoding and content_type fields to read."
                persistent-hint
              ></v-text-field>
            </v-col>

            <v-col v-if="object.traceback" cols="12" md="12">
              <v-textarea
                :value="object.traceback"
                label="Traceback"
                hint="Text of the traceback if the task generated one"
                persistent-hint
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field
                :value="object.meta"
                label="Task Meta Information"
                hint="JSON meta information about the task, such as information on child tasks"
                persistent-hint
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions
          ><v-spacer></v-spacer>
          <div class="caption">
            Task Result ID <strong>{{ object.pk }}</strong>
          </div></v-card-actions
        >
      </v-card>
    </v-form>

    <DeleteConfirmation @confirm-delete="deleteTaskResultRedirect" />
  </div>
</template>

<script>
import { hasPermission, localDatetime } from '@/spaday/helpers'
import { mapState, mapActions } from 'vuex'
import { DeleteConfirmation, FormHeader, CardSubheader } from '@/spaday/components'

export default {
  components: {
    DeleteConfirmation,
    FormHeader,
    CardSubheader,
  },
  data: () => ({
    headers: [
      {
        text: '#',
        value: '#',
      },
      {
        text: 'Field',
        value: 'field',
      },
      {
        text: 'From',
        value: 'from',
      },
      {
        text: 'To',
        value: 'to',
      },
    ],
  }),
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      object: (state) => state.taskresults.object,
    }),
    statusColor() {
      if (this.object.status === 'FAILURE') {
        return 'error'
      } else if (this.object.status === 'SUCCESS') {
        return 'success'
      }
      return 'warning'
    },
  },
  mounted() {
    this.getTaskResult(this.$route.params.pk)
  },
  beforeDestroy() {
    this.clearTaskResult()
  },
  methods: {
    hasPermission,
    localDatetime,
    ...mapActions('taskresults', {
      getTaskResult: 'get',
      updateTaskResult: 'update',
      preDeleteTaskResult: 'preDelete',
      deleteTaskResult: 'delete',
      clearTaskResult: 'clear',
    }),
    deleteTaskResultRedirect(object) {
      this.deleteTaskResult(object).then(() => {
        this.$router.go(-1)
      })
    },
  },
}
</script>
