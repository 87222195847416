<template>
  <v-dialog v-model="dialog" max-width="50%">
    <template v-slot:activator="{ on, attrs }">
      <v-btn small v-bind="attrs" v-on="on" color="error" text outlined
        >Change Password <v-icon right>mdi-lock-check</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-toolbar dark flat dense color="primary">
        <v-toolbar-title><v-icon left>mdi-lock-check</v-icon> Change password: {{ user.email }}</v-toolbar-title>
      </v-toolbar>

      <v-form ref="form">
        <v-card-subtitle
          >Enter a new password for the user <strong>{{ user.last_name }}, {{ user.first_name }}</strong
          >.</v-card-subtitle
        >

        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                :type="showPass ? 'text' : 'password'"
                v-model="new_password1"
                :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPass = !showPass"
                label="Password"
                :rules="[rules.required, rules.min]"
                persistent-hint
                hint="At least 8 characters"
                counter
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                :type="showPass ? 'text' : 'password'"
                v-model="new_password2"
                :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPass = !showPass"
                label="Password Confirmation"
                :rules="[confirmPassword]"
                persistent-hint
                hint="Enter the same password as before, for verification."
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="hide" text> Cancel </v-btn>
          <v-btn @click="submit" color="primary"> Change password </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    user: { type: Object },
  },
  data: () => ({
    new_password1: '',
    new_password2: '',
    showPass: false,
    dialog: false,
    rules: {
      required: (value) => !!value || 'Required.',
      min: (v) => v.length >= 8 || 'At least 8 characters',
    },
  }),
  methods: {
    ...mapActions('users', {
      changePassword: 'changePassword',
    }),
    submit() {
      if (!this.$refs.form.validate()) return
      let payload = {
        password: this.new_password1,
        password2: this.new_password2,
      }
      this.changePassword({ id: this.user.pk, data: payload }).then(() => {
        this.hide()
      })
    },
    hide() {
      this.dialog = false
      this.new_password1 = ''
      this.new_password2 = ''
    },
    confirmPassword() {
      return this.new_password1 === this.new_password2 || 'Password must match'
    },
  },
}
</script>
