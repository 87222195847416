<template>
  <div>
    <!-- <ListHeader title="Devices" subtitle="Create and manage devices." /> -->

    <v-card class="mb-4" color="transparent">
      <v-card-title class="text--primary">Xapp Agent Register Command</v-card-title>
      <v-card-subtitle
        >Once Xapp Agent is <a href="" window="_blank">installed</a> on your device, copy and paste the below command to
        your device terminal. This command will register your device on the Agent list.</v-card-subtitle
      >
      <v-card-text>
        <pre class="source-code">~/.venvs/xappmonitor_env/bin/xapp-monitor -t {{ token }}</pre>
        <!-- curl -O https://xapp.xstudios/xapp_install.sh && chmod +wx xapp_install.sh && sudo ./xapp_install.sh -a "" -->
        <small
          >This is a short-lived token which will expire <b>{{ token_expiration }}</b
          >.</small
        >
      </v-card-text>
    </v-card>

    <v-card>
      <v-card-title class="text--primary">
        <SearchField label="Search Devices..." @search="updateSearch" />
      </v-card-title>
      <v-data-table
        v-model="deviceTable.selected"
        item-key="pk"
        loading-text="Loading. Please wait..."
        :loading="devices.loading"
        :items="devices.items"
        :headers="deviceTable.headers"
        :search="deviceTable.search"
        :footer-props="{
          'items-per-page-options': [10, 20, 50, 100],
          'show-current-page': true,
        }"
        :options.sync="deviceTable.options"
        :server-items-length="deviceTable.totalItems"
        @update:options="getPaginatedDevices"
      >
        <template v-slot:item.pk="{ item }">
          {{ item.pk.slice(0, 8) }}
        </template>
        <template v-slot:item.tags="{ item }">
          <v-chip v-for="(tag, index) in item.tags" :key="index" class="action-chip" small>
            {{ tag.name }}
          </v-chip>
        </template>
        <template v-slot:item.created_at="{ item }">
          {{ localDatetime(item.created_at) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <RowActions
            v-if="hasAnyPermission(['core.change_device', 'core.delete_device'])"
            :actions="deviceTable.actions"
            @edit="editDevice(item)"
            @confirm-delete="preDeleteDevice(item)"
          />
          <span v-else>--</span>
        </template>
      </v-data-table>
    </v-card>

    <DeleteConfirmation @confirm-delete="deleteDevice" />
  </div>
</template>

<script>
import { hasPermission, hasAnyPermission, localDatetime, getPaginationParams } from '@/spaday/helpers'
import { mapState, mapActions } from 'vuex'
import { DeleteConfirmation, RowActions, SearchField } from '@/spaday/components'
import { DeviceService } from '@/services'

export default {
  components: {
    RowActions,
    DeleteConfirmation,
    // ListHeader,
    SearchField,
  },
  data: () => ({
    token: null,
    token_expiration: null,

    deviceTable: {
      options: {
        itemsPerPage: 20,
      },
      totalItems: 0,
      search: '',
      selected: [],
      headers: [
        { text: 'ID', value: 'pk', sortable: false },
        { text: 'Name', value: 'name' },
        { text: 'Client', value: 'client_display', sortable: false },
        { text: 'Tags', value: 'tags', sortable: false },
        { text: 'Operating System', value: 'os' },
        { text: 'Manufacturer', value: 'manufacturer' },
        { text: 'Model', value: 'model' },
        { text: 'Created', value: 'created_at' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      actions: [
        {
          text: 'Edit Device',
          icon: 'mdi-pencil',
          color: 'primary',
          permissions: ['core.change_device'],
          emit: 'edit',
        },
        {
          text: 'Delete Device',
          icon: 'mdi-trash-can',
          color: 'error',
          permissions: ['core.delete_device'],
          emit: 'confirm-delete',
        },
      ],
    },
  }),
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      devices: (state) => state.devices.all,
    }),
  },
  mounted() {
    // this.getDevices()
    this.getToken()
  },
  methods: {
    hasPermission,
    hasAnyPermission,
    getPaginationParams,
    localDatetime,
    ...mapActions('devices', {
      getDevices: 'getAll',
      preDeleteDevice: 'preDelete',
      deleteDevice: 'delete',
    }),
    editDevice(item) {
      this.$router.push({ name: 'ChangeDevice', params: { pk: item.pk } })
    },

    /** Pagination handlers **/
    getPaginatedDevices() {
      const params = this.getPaginationParams(this.deviceTable.options, this.deviceTable.search)
      this.getDevices(params).then((data) => {
        this.deviceTable.totalPages = data.num_pages
        this.deviceTable.totalItems = data.num_results
      })
    },
    updateSearch(value) {
      this.deviceTable.search = value
      this.getPaginatedDevices()
    },

    getToken() {
      DeviceService.getToken().then((data) => {
        this.token = data.access_token
        this.token_expiration = this.getTokenExpiration()
      })
    },

    getTokenExpiration() {
      // console.log('get token expiration')
      var now = new Date()
      now.setMinutes(now.getMinutes() + 30)
      now = new Date(now)
      return now.toLocaleString()
    },
  },
}
</script>

<style lang="scss">
pre.source-code {
  color: #fff;
  background-color: #424242;
  font-size: 14px;
  padding: 14px;
  overflow-x: scroll;
  width: 100%;
  line-height: 14px;
}
.action-chip {
  margin-right: 4px;
}
</style>
