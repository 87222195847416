<template>
  <div>
    <FormHeader
      title="Update Client"
      :subtitle="object.name"
      btnLabel="Update Client"
      :canChange="hasPermission('core.change_client')"
      :canDelete="hasPermission('core.delete_client')"
      @submit="submit"
      @delete="preDeleteClient(object)"
      :deleteLoading="$store.state.clients.delete.loading"
      :saveLoading="$store.state.clients.object.loading"
    />

    <v-form ref="form" :disabled="object.loading" :readonly="!hasPermission('core.change_client')">
      <v-card class="mb-4" :loading="object.loading">
        <v-tabs v-model="tab" grow icons-and-text slider-size="4">
          <v-tabs-slider></v-tabs-slider>
          <template v-for="(item, index) in tabs">
            <v-tab :key="index" :href="item.href">
              {{ item.text }}
              <v-icon>{{ item.icon }}</v-icon>
            </v-tab>
          </template>
        </v-tabs>

        <v-divider class="primary"></v-divider>

        <v-tabs-items v-model="tab">
          <v-tab-item value="general">
            <v-card-text>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field v-model="object.name" label="Name" :rules="[rules.required]"></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-tab-item>

          <v-tab-item value="users">
            <UserList :user="object" />
          </v-tab-item>
        </v-tabs-items>
        <v-divider></v-divider>
        <v-card-actions
          ><v-spacer></v-spacer>
          <div class="caption">
            Client ID <strong>{{ object.pk }}</strong>
          </div></v-card-actions
        >
      </v-card>
    </v-form>

    <DeleteConfirmation @confirm-delete="deleteRedirect" />
  </div>
</template>

<script>
import { hasPermission, localDatetime } from '@/spaday/helpers'
import { mapState, mapActions } from 'vuex'
import { FormHeader, DeleteConfirmation } from '@/spaday/components'
import UserList from '@/views/client/components/UserList'

export default {
  components: {
    DeleteConfirmation,
    FormHeader,
    UserList,
  },
  data: () => ({
    rules: {
      required: (value) => !!value || 'Required.',
      min: (v) => v.length >= 8 || 'At least 8 characters',
    },
    tabs: [
      {
        text: 'General Info',
        icon: 'mdi-information',
        href: '#general',
      },
      {
        text: 'Users',
        icon: 'mdi-account-multiple',
        href: '#users',
      },
    ],
  }),
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      object: (state) => state.clients.object,
    }),
    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } })
      },
      get() {
        return this.$route.query.tab
      },
    },
  },
  mounted() {
    this.getClient(this.$route.params.pk)
  },
  beforeDestroy() {
    this.clearClient()
  },
  methods: {
    hasPermission,
    localDatetime,
    ...mapActions('clients', {
      getClient: 'get',
      updateClient: 'update',
      preDeleteClient: 'preDelete',
      deleteClient: 'delete',
      clearClient: 'clear',
    }),
    submit() {
      if (!this.$refs.form.validate()) return

      this.updateClient({ id: this.object.pk, data: this.object }).then(() => {
        this.$router.go(-1)
      })
    },
    deleteRedirect(object) {
      this.deleteClient(object).then(() => {
        this.$router.go(-1)
      })
    },
  },
}
</script>
