<template>
  <div v-if="progress">
    <v-progress-linear :value="progress" rounded striped color="success" :height="height">
      <template v-slot:default="{ value }">
        <strong>{{ Math.ceil(value) }}%</strong>
      </template>
    </v-progress-linear>
  </div>
</template>

<script>
import store from '@/store'
import { mapState } from 'vuex'

export default {
  name: 'UploadProgress',
  props: {
    height: { type: Number, default: 20 },
  },
  //   mounted() {
  //     store.dispatch('uploadProgress/reset')
  //   },
  beforeDestroy() {
    store.dispatch('uploadProgress/reset')
  },
  computed: {
    ...mapState({
      progress: (state) => state.uploadProgress.progress,
    }),
  },
}
</script>
