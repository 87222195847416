<template>
  <v-app>
    <div>
      <SideNav :items="sidenavItems" />

      <Header />

      <v-main>
        <v-container fluid>
          <Alert />
          <router-view></router-view>
        </v-container>
      </v-main>

      <v-footer app dark>
        <Footer />
      </v-footer>

      <Toast />
    </div>
  </v-app>
</template>

<script>
import { SIDENAV } from '@/constants'
import { mapActions } from 'vuex'
import { Header, Footer, SideNav } from '@/spaday/components/layout'
import { Alert, Toast } from '@/spaday/components'

export default {
  name: 'App',
  components: {
    Alert,
    Footer,
    Header,
    SideNav,
    Toast,
  },
  data: () => ({
    sidenavItems: SIDENAV,
  }),
  methods: {
    ...mapActions({
      clearAlert: 'alert/clear',
    }),
  },
  watch: {
    $route() {
      this.clearAlert()
    },
  },
}
</script>

<style lang="scss">
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.03);
}
.theme--dark.v-data-table tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.5);
}
.disabled {
  pointer-events: none;
  opacity: 0.3;
}
</style>
