<template>
  <v-dialog v-model="deleteDialog.show" max-width="50%">
    <v-card>
      <v-toolbar dark flat dense color="error">
        <v-toolbar-title>
          <span v-if="deleteDialog.protected.length === 0">Are you sure?</span
          ><span v-else>Cannot delete {{ deleteDialog.model }}.</span>
        </v-toolbar-title>
      </v-toolbar>

      <!-- <v-card-title class="text-h5" dark>
        <span v-if="deleteDialog.protected.length === 0">Are you sure?</span
        ><span v-else>Cannot delete {{ deleteDialog.model }}.</span>
      </v-card-title> -->

      <v-card-text class="pa-4">
        <p>
          Are you sure you want to delete the {{ deleteDialog.model }} "<strong>{{ deleteDialog.name }}</strong
          >"? All of the following related items will be deleted:
        </p>

        <div v-if="deleteDialog.modelCount">
          <span class="text-h6">Summary</span>
          <ul>
            <li v-for="(item, index) in deleteDialog.modelCount" :key="`summary_${index}`">
              <span class="text-uppercase">{{ item[0] }}</span
              >: {{ item[1] }}
            </li>
          </ul>
        </div>

        <!-- <div v-if="deleteDialog.deletedObjects">
          <span class="text-h6">Objects</span>
          <ul>
            <li v-for="(item, index) in deleteDialog.deletedObjects" :key="`object_${index}`">
              <span v-if="Array.isArray(item)">
                <ul>
                  <li v-for="(subitem, index) in item" :key="`model_${index}`">
                    {{ subitem }}
                  </li>
                </ul>
              </span>
              <span v-else>{{ item }}</span>
            </li>
          </ul>
        </div> -->
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="hide" text> Cancel </v-btn>
        <v-btn @click="confirm" color="error"> yes, i'm sure </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  computed: {
    ...mapState({
      deleteDialog: (state) => state.deleteDialog,
    }),
  },
  methods: {
    ...mapActions('deleteDialog', {
      clear: 'clear',
    }),
    hide() {
      this.clear()
    },
    confirm() {
      // deep copy the data as it will be cleared
      let objCopy = JSON.parse(JSON.stringify(this.deleteDialog))
      this.$emit('confirm-delete', objCopy)
      this.clear()
    },
  },
}
</script>
