const state = {
  search: '',
  column: '',
  order: false,
  page: 1,
}

const actions = {
  setSearch({ commit }, search) {
    commit('SET_SEARCH_SUCCESS', search)
    state.search = search
  },
  setColumn({ commit }, column) {
    commit('SET_COLUMN_SUCCESS', column)
  },
  setOrder({ commit }, order) {
    commit('SET_ORDER_SUCCESS', order)
  },
  setPageNumber({ commit }, page) {
    commit('SET_PAGE_NUMBER_SUCCESS', page)
  },
}
const getters = {
  getInfo({ state }) {
    return state
  },
}
const mutations = {
  SET_SEARCH_SUCCESS(state, search) {
    state.search = search
  },
  SET_COLUMN_SUCCESS(state, column) {
    state.column = column
  },
  SET_ORDER_SUCCESS(state, order) {
    state.order = order
  },
  SET_PAGE_NUMBER_SUCCESS(state, page) {
    state.page = page
  },
}

export const datatable = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
