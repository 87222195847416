import http from '@/spaday/http-common'

class OperatingSystemService {
  /** Base CRUD REST calls **/
  getAll(params = {}) {
    return http.get('/operating-systems/', { params })
  }

  //   get(pk) {
  //     return http.get(`/operating-systes/${pk}/`)
  //   }

  //   create(data) {
  //     return http.post('/operating-systems/', data)
  //   }

  //   update(pk, data) {
  //     return http.put(`/operating-systems/${pk}/`, data)
  //   }

  //   preDelete(pk) {
  //     return http.get(`/operating-systems/${pk}/confirm-delete/`)
  //   }

  //   delete(pk) {
  //     return http.delete(`/operating-systems/${pk}/`)
  //   }

  /** Specialized REST calls **/
  combobox() {
    return http.get(`/operating-systems/combobox/`)
  }
}

export default new OperatingSystemService()
