export const getPaginationParams = (options, search = null) => {
  const { sortBy, sortDesc, page, itemsPerPage } = options

  let params = {
    page: page,
    size: itemsPerPage,
  }

  if (sortBy !== undefined && sortBy.length > 0) {
    let sort = sortBy.map((x) => x)
    sortDesc.forEach((value, index) => {
      sort[index] = value === true ? `-${sort[index]}` : sort[index]
    })
    params['ordering'] = sort.join()
  }

  if (search) params['search'] = search

  return params
}
