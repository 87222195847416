export const getCsrfToken = () => {
  let csrfToken = null
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';')
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim()
      if (cookie.substring(0, 10) === 'csrftoken=') {
        csrfToken = decodeURIComponent(cookie.substring(10))
        break
      }
    }
  }
  return csrfToken
}
