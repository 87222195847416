import http from '@/spaday/http-common'

class TagService {
  /** Base CRUD REST calls **/
  getAll(params = {}) {
    return http.get('/tags/', { params })
  }

  get(pk) {
    return http.get(`/tags/${pk}/`)
  }

  create(data) {
    return http.post('/tags/', data)
  }

  update(pk, data) {
    return http.put(`/tags/${pk}/`, data)
  }

  preDelete(pk) {
    return http.get(`/tags/${pk}/confirm-delete/`)
  }

  delete(pk) {
    return http.delete(`/tags/${pk}/`)
  }

  /** Specialized REST calls **/

  combobox() {
    return http.get(`/tags/combobox/`)
  }

  //   users(pk) {
  //     return http.get(`/groups/${pk}/users/`)
  //   }

  //   addUsers(pk, ids) {
  //     return http.post(`/groups/${pk}/add-users/`, {
  //       users: ids,
  //     })
  //   }

  //   removeUsers(pk, ids) {
  //     return http.post(`/groups/${pk}/remove-users/`, {
  //       users: ids,
  //     })
  //   }
}

export default new TagService()
