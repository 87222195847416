<template>
  <v-card class="mb-4">
    <v-toolbar dark flat dense color="primary">
      <v-icon left>mdi-history</v-icon>
      <v-toolbar-title>Recent Logins</v-toolbar-title>
    </v-toolbar>

    <v-data-table
      item-key="email"
      :loading="recentLogins.loading"
      loading-text="Loading. Please wait..."
      :items="recentLogins.items"
      :headers="headers"
      hide-default-footer
      disable-sort
    >
      <template v-slot:item.is_superuser="{ item }">
        <RowCheckmark :bool="item.is_superuser" />
      </template>
      <template v-slot:item.is_staff="{ item }">
        <RowCheckmark :bool="item.is_staff" />
      </template>
      <template v-slot:item.last_login="{ item }">
        {{ localDatetime(item.last_login) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <RowActions v-if="hasPermission('user.change_user')" :actions="actions" @edit="editUser(item)" />
        <span v-else>--</span>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { localDatetime } from '@/spaday/helpers'
import { mapState, mapActions } from 'vuex'
import { RowCheckmark, RowActions } from '@/spaday/components'
import { hasPermission } from '@/spaday/helpers'

export default {
  name: 'RecentLogins',
  components: {
    RowCheckmark,
    RowActions,
  },
  data: () => ({
    headers: [
      {
        text: 'First Name',
        value: 'first_name',
      },
      {
        text: 'Last Name',
        value: 'last_name',
      },
      {
        text: 'Email',
        value: 'email',
      },
      {
        text: 'Staff',
        value: 'is_staff',
      },
      {
        text: 'Superuser',
        value: 'is_superuser',
      },
      {
        text: 'Last Login',
        value: 'last_login',
      },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    actions: [
      {
        text: 'Edit User',
        icon: 'mdi-pencil',
        color: 'primary',
        permissions: ['users.change_user'],
        emit: 'edit',
      },
    ],
  }),
  computed: {
    ...mapState({
      recentLogins: (state) => state.users.recentLogins,
    }),
  },
  mounted() {
    this.getRecentLogins()
  },
  methods: {
    hasPermission,
    localDatetime,
    ...mapActions('users', {
      getRecentLogins: 'getRecentLogins',
    }),
    editUser(item) {
      this.$router.push({ name: 'ChangeUser', params: { pk: item.pk } })
    },
  },
}
</script>
