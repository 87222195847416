<template>
  <v-card class="flex">
    <v-toolbar dark flat dense color="primary">
      <v-icon left>{{ icon }}</v-icon>
      <v-toolbar-title> {{ title }} </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <slot name="content">
        <h1 v-if="value && animated"><AnimatedInteger :value="value" :formatter="formatter()" /></h1>
        <h1 v-else-if="value">{{ formattedValue }}</h1>
        <h1 v-else>--</h1>
        <slot name="append"></slot>
      </slot>
      <v-divider v-if="description" class="my-4"></v-divider>
      <slot name="description">
        {{ description }}
      </slot>
    </v-card-text>
  </v-card>
</template>

<script>
import { AnimatedInteger } from '@/spaday/components'

export default {
  name: 'StatCard',
  components: {
    AnimatedInteger,
  },
  props: {
    icon: { type: String },
    title: { type: String },
    description: { type: String },
    value: { default: '--' },
    units: { type: String, default: 'number' },
    animated: { type: Boolean, default: false },
  },
  computed: {
    formattedValue() {
      return this.formatter()(this.value)
    },
  },
  methods: {
    formatter() {
      switch (this.units) {
        case 'number':
          return this.formatNumber
        case 'bytes':
          if (this.value < 1024 * 1024 * 1024) {
            return this.formatMB
          }
          return this.formatGB
        case 'mb':
          return this.formatMB
        case 'gb':
          return this.formatGB
        case 'percent':
          return this.formatPercent
        case 'string':
          return this.formatString
        default:
          return this.formatString
      }
    },
    formatNumber(value) {
      if (isNaN(value)) {
        return '--'
      }
      return new Intl.NumberFormat('en-US').format(value)
    },
    formatGB(value) {
      if (isNaN(value)) {
        return '--'
      }
      return this.formatNumber((value / (1024 * 1024 * 1024)).toFixed(2)) + ' GB'
    },
    formatMB(value) {
      if (isNaN(value)) {
        return '--'
      }
      return this.formatNumber((value / (1024 * 1024)).toFixed(2)) + ' MB'
    },
    formatPercent(value) {
      if (isNaN(value)) {
        return '--'
      }
      return value + '%'
    },
    formatString(value) {
      return value
    },
  },
}
</script>
