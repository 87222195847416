<template>
  <v-app-bar app dark clipped-left>
    <v-img src="@/assets/x-logo-gradient.png" contain max-height="30" max-width="30"></v-img>
    <v-toolbar-title class="ml-2"> {{ projectName }} </v-toolbar-title>

    <v-spacer></v-spacer>
    <v-toolbar-items class="hidden-sm-and-down">
      <v-menu offset-y v-if="status.loggedIn || user" :close-on-content-click="false">
        <template v-slot:activator="{ on: menu, attrs }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn depressed v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                <v-avatar color="secondary"
                  ><span v-if="user.initials" class="text-h6">{{ user.initials }}</span>
                  <v-icon v-else>mdi-account</v-icon></v-avatar
                >
              </v-btn>
            </template>
            <span>Account</span>
          </v-tooltip>
        </template>

        <DenseList :items="actions" @logout="logout()">
          <template v-slot:prepend>
            <v-list-item two-line class="px-2">
              <v-list-item-avatar color="secondary">
                <span v-if="user.initials">{{ user.initials }}</span>
                <v-icon v-else>mdi-account</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{ user.first_name }} {{ user.last_name }}</v-list-item-title>
                <v-list-item-subtitle v-if="user.is_superuser">Superuser</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mb-1"></v-divider>
            <v-list-item dense>
              <v-switch dense :value="darkMode" @change="toggleTheme" inset>
                <template v-slot:label><span class="dense"> Dark Mode</span></template>
              </v-switch>
            </v-list-item>
            <v-divider class="mb-1"></v-divider>
          </template>
        </DenseList>
      </v-menu>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
import { VERSION, PROJECT_NAME } from '@/constants'
import { mapState, mapActions } from 'vuex'
import { hasPermission, hasAnyPermission } from '@/spaday/helpers'
import { DenseList } from '@/spaday/components'

export default {
  name: 'Header',
  components: {
    DenseList,
  },
  props: {
    version: { type: String, default: VERSION },
    projectName: { type: String, default: PROJECT_NAME },
  },
  data: () => ({
    actions: [
      {
        text: 'Logout',
        icon: 'mdi-logout',
        color: 'error',
        permissions: [],
        emit: 'logout',
      },
      //   {
      //     divider: true,
      //   },
      //   {
      //     header: 'Internal Tools',
      //   },
      //   {
      //     text: 'Xapp Admin',
      //     icon: 'mdi-open-in-new',
      //     color: '',
      //     permissions: [],
      //     emit: 'open-xapp',
      //   },
      //    {
      //     text: 'X Enterprise Admin',
      //     icon: 'mdi-open-in-new',
      //     color: '',
      //     permissions: [],
      //     emit: 'open-xapp',
      //   },
    ],
  }),
  computed: {
    ...mapState('account', ['status', 'user']),
    ...mapState('preferences', ['darkMode']),
  },
  methods: {
    hasPermission,
    hasAnyPermission,
    ...mapActions('account', ['logout']),
    ...mapActions('preferences', ['toggleDarkMode']),
    toggleTheme() {
      this.toggleDarkMode()
    },
  },
}
</script>

<style scss scoped>
.theme--dark .dense {
  font-size: 13px;
  font-weight: 500;
  color: white;
}

.theme--light .dense {
  font-size: 13px;
  font-weight: 500;
  color: black;
}
</style>
