import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: true,
    // options: {
    //     themeCache: {
    //         get: key => localStorage.getItem(key),
    //         set: (key, value) => localStorage.setItem(key, value),
    //     },
    // },
    themes: {
      light: {
        primary: '#6967CE',
        secondary: '#2EAFD0',
        accent: '#8c9eff',
        success: '#4caf50',
      },
      dark: {
        primary: '#6967CE',
        secondary: '#2EAFD0',
        accent: '#8c9eff',
        success: '#4caf50',
      },
    },
  },
})
