import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import VueCookies from 'vue-cookies'
// import VeeValidate from 'vee-validate'

import vuetify from '@/plugins/vuetify'
// import '@/plugins/fontawesome'
Vue.use(VueCookies)
// Vue.use(VeeValidate)

// import LogRocket from 'logrocket'
// LogRocket.init('nm1oqt/xapp')

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
  beforeCreate() {
    // this.$store.commit('initialiseStore')
  },
}).$mount('#app')
