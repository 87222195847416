<template>
  <div>
    <ListHeader
      title="Terminal Commands"
      subtitle="Create and manage terminal commands."
      btnLabel="New Terminal Command"
      :route="{ name: 'AddTerminalCommand' }"
      :canAdd="hasPermission('core.add_terminalcommand')"
    />

    <v-card>
      <v-card-title class="text--primary">
        <SearchField label="Search Terminal Commands..." @search="updateSearch" />
      </v-card-title>
      <v-data-table
        v-model="commandTable.selected"
        item-key="pk"
        loading-text="Loading. Please wait..."
        :loading="commands.loading"
        :items="commands.items"
        :headers="commandTable.headers"
        :search="commandTable.search"
        :footer-props="{
          'items-per-page-options': [10, 20, 50, 100],
          'show-current-page': true,
        }"
        :options.sync="commandTable.options"
        :server-items-length="commandTable.totalItems"
        @update:options="getPaginatedCommands"
      >
        <template v-slot:item.os="{ item }">
          <PlatformIcon :os="item.os_value" :display_name="item.os_name" />
        </template>
        <template v-slot:item.published="{ item }">
          <RowCheckmark :bool="item.published" />
        </template>
        <template v-slot:item.actions="{ item }">
          <RowActions
            :actions="commandTable.actions"
            @edit="editTerminalCommand(item)"
            @confirm-delete="preDeleteTerminalCommand(item)"
          />
        </template>
      </v-data-table>
    </v-card>

    <DeleteConfirmation @confirm-delete="deleteTerminalCommand" />
  </div>
</template>

<script>
import { hasPermission, localDatetime, getPaginationParams } from '@/spaday/helpers'
import { mapState, mapActions } from 'vuex'
import {
  ListHeader,
  DeleteConfirmation,
  RowActions,
  RowCheckmark,
  PlatformIcon,
  SearchField,
} from '@/spaday/components'

export default {
  components: {
    RowActions,
    DeleteConfirmation,
    PlatformIcon,
    RowCheckmark,
    ListHeader,
    SearchField,
  },
  data: () => ({
    commandTable: {
      options: {
        itemsPerPage: 20,
      },
      totalItems: 0,
      search: '',
      selected: [],
      headers: [
        { text: 'Label', value: 'label' },
        { text: 'Operating System', value: 'os' },
        { text: 'Published', value: 'published' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      actions: [
        {
          text: 'Edit Terminal Command',
          icon: 'mdi-pencil',
          color: 'primary',
          permissions: ['core.change_terminalcommand'],
          emit: 'edit',
        },
        {
          text: 'Delete Terminal Command',
          icon: 'mdi-trash-can-outline',
          color: 'error',
          permissions: ['core.delete_terminalcommand'],
          emit: 'confirm-delete',
        },
      ],
    },
  }),
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      commands: (state) => state.terminalcommands.all,
    }),
  },
  mounted() {
    // this.getTerminalCommands()
  },
  methods: {
    hasPermission,
    localDatetime,
    getPaginationParams,
    ...mapActions('terminalcommands', {
      getTerminalCommands: 'getAll',
      preDeleteTerminalCommand: 'preDelete',
      deleteTerminalCommand: 'delete',
    }),
    editTerminalCommand(item) {
      this.$router.push({ name: 'ChangeTerminalCommand', params: { pk: item.pk } })
    },

    /** Pagination handlers **/
    getPaginatedCommands() {
      const params = this.getPaginationParams(this.commandTable.options, this.commandTable.search)
      this.getTerminalCommands(params).then((data) => {
        this.commandTable.totalPages = data.num_pages
        this.commandTable.totalItems = data.num_results
      })
    },
    updateSearch(value) {
      this.commandTable.search = value
      this.getPaginatedCommands()
    },
  },
}
</script>
