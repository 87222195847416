import http from '@/spaday/http-common'

class TaskResultService {
  /** Base CRUD REST calls **/
  getAll(params = {}) {
    return http.get('/taskresults/', { params })
  }

  get(pk) {
    return http.get(`/taskresults/${pk}/`)
  }

  preDelete(pk) {
    return http.get(`/taskresults/${pk}/confirm-delete/`)
  }

  delete(pk) {
    return http.delete(`/taskresults/${pk}/`)
  }

  /** Specialized REST calls **/
}

export default new TaskResultService()
