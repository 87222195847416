<template>
  <div>
    <FormHeader
      title="Create Terminal Command"
      :subtitle="object.label"
      btnLabel="Save Terminal Command"
      :canAdd="hasPermission('core.add_terminalcommand')"
      @submit="submit"
      :saveLoading="$store.state.terminalcommands.object.loading"
    />

    <v-form ref="form">
      <v-card class="mb-4">
        <v-toolbar dark flat dense color="primary">
          <v-icon left>mdi-information-variant</v-icon>
          <v-toolbar-title> General Info </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-select
                v-model="object.os"
                label="Operating System"
                :items="operatingSystems.items"
                :loading="operatingSystems.loading"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field v-model="object.label" label="Label" :rules="[rules.required]"></v-text-field>
            </v-col>
            <v-col cols="12" md="9">
              <v-text-field v-model="object.value" label="Value" :rules="[rules.required]"></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-checkbox
                v-model="object.published"
                label="Published"
                hint="Only published commands will be available."
                persistent-hint
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { hasPermission } from '@/spaday/helpers'
import { mapActions, mapState } from 'vuex'
import { FormHeader } from '@/spaday/components'

export default {
  components: {
    FormHeader,
  },
  data: () => ({
    object: {},
    rules: {
      required: (value) => !!value || 'Required.',
      min: (v) => v.length >= 8 || 'At least 8 characters',
    },
  }),
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      operatingSystems: (state) => state.operatingsystems.combobox,
    }),
  },
  mounted() {
    this.getOperatingSystems()
  },
  methods: {
    hasPermission,
    ...mapActions('operatingsystems', {
      getOperatingSystems: 'getCombobox',
    }),
    ...mapActions('terminalcommands', {
      createTerminalCommand: 'create',
    }),
    submit() {
      if (!this.$refs.form.validate()) return

      this.createTerminalCommand(this.object).then(() => {
        this.$router.go(-1)
      })
    },
  },
}
</script>
