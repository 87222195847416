<template>
  <div>
    <FormHeader
      title="View Log Entry"
      :subtitle="object.resource"
      :canDelete="hasPermission('auditlog.delete_logentry')"
      @delete="preDeleteLog(object)"
      :deleteLoading="$store.state.auditlog.delete.loading"
    >
      <template v-slot:subtitle-prepend>
        <v-chip small :color="actionColor">{{ object.action }}</v-chip> <v-chip small>{{ object.model }}</v-chip>
      </template></FormHeader
    >

    <v-form ref="form" disabled :readonly="true">
      <v-card class="mb-4" :loading="object.loading">
        <v-card-text>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field :value="object.model" label="Model"></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field :value="object.object_repr" label="Resource"></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field :value="object.user" label="User"></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field :value="`${localDatetime(object.timestamp)}`" label="Timestamp"></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field :value="object.remote_addr" label="Remote Address"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <CardSubheader icon="mdi-clipboard-text-clock-outline" title="Changes" />
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-data-table hide-default-footer disable-sort :headers="headers" :items="object.changes"></v-data-table>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions
          ><v-spacer></v-spacer>
          <div class="caption">
            Log Entry ID <strong>{{ object.pk }}</strong>
          </div></v-card-actions
        >
      </v-card>
    </v-form>

    <DeleteConfirmation @confirm-delete="deleteLogRedirect" />
  </div>
</template>

<script>
import { hasPermission, localDatetime } from '@/spaday/helpers'
import { mapState, mapActions } from 'vuex'
import { DeleteConfirmation, FormHeader, CardSubheader } from '@/spaday/components'

export default {
  components: {
    DeleteConfirmation,
    FormHeader,
    CardSubheader,
  },
  data: () => ({
    headers: [
      { text: '#', value: '#' },
      { text: 'Field', value: 'field' },
      { text: 'From', value: 'from' },
      { text: 'To', value: 'to' },
    ],
  }),
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      object: (state) => state.auditlog.object,
    }),
    actionColor() {
      if (this.object.action === 'Delete') {
        return 'error'
      } else if (this.object.action === 'Update') {
        return 'warning'
      }
      return 'success'
    },
  },
  mounted() {
    this.getLog(this.$route.params.pk)
  },
  beforeDestroy() {
    this.clearLog()
  },
  methods: {
    hasPermission,
    localDatetime,
    ...mapActions('auditlog', {
      getLog: 'get',
      updateLog: 'update',
      preDeleteLog: 'preDelete',
      deleteLog: 'delete',
      clearLog: 'clear',
    }),
    deleteLogRedirect(object) {
      this.deleteLog(object).then(() => {
        this.$router.go(-1)
      })
    },
  },
}
</script>
