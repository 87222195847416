import http from '@/spaday/http-common'

class TerminalCommandService {
  /** Base CRUD REST calls **/
  getAll(params = {}) {
    return http.get('/commands/', { params })
  }

  get(pk) {
    return http.get(`/commands/${pk}/`)
  }

  create(data) {
    return http.post('/commands/', data)
  }

  update(pk, data) {
    return http.put(`/commands/${pk}/`, data)
  }

  preDelete(pk) {
    return http.get(`/commands/${pk}/confirm-delete/`)
  }

  delete(pk) {
    return http.delete(`/commands/${pk}/`)
  }

  /** Specialized REST calls **/

  combobox() {
    return http.get(`/commands/dropdown/`)
  }
}

export default new TerminalCommandService()
