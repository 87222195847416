<template>
  <div>
    <ListHeader
      title="Clients"
      subtitle="Create and manage clients."
      btnLabel="New Client"
      :route="{ name: 'AddClient' }"
      :canAdd="hasPermission('core.add_client')"
    />

    <v-card>
      <v-card-title class="text--primary">
        <SearchField label="Search Clients..." @search="updateSearch" />
      </v-card-title>
      <v-data-table
        v-model="clientTable.selected"
        item-key="name"
        loading-text="Loading. Please wait..."
        :loading="clients.loading"
        :items="clients.items"
        :headers="clientTable.headers"
        :search="clientTable.search"
        :footer-props="{
          'items-per-page-options': [10, 20, 50, 100],
          'show-current-page': true,
        }"
        :options.sync="clientTable.options"
        :server-items-length="clientTable.totalItems"
        @update:options="getPaginatedClients"
        @update:sort-by="sortTable"
        @update:sort-desc="sortOrder"
        @update:page="page"
        :sort-by="dt.column"
        :sort-desc="dt.order"
        :page="dt.page"
      >
        <template v-slot:item.created_at="{ item }">
          {{ localDatetime(item.created_at) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <RowActions :actions="clientTable.actions" @edit="editClient(item)" @confirm-delete="preDeleteClient(item)" />
        </template>
      </v-data-table>
    </v-card>

    <DeleteConfirmation @confirm-delete="deleteClient" />
  </div>
</template>

<script>
import { hasPermission, localDatetime, getPaginationParams } from '@/spaday/helpers'
import { mapState, mapActions } from 'vuex'
import { DeleteConfirmation, ListHeader, RowActions, SearchField } from '@/spaday/components'

export default {
  components: {
    RowActions,
    DeleteConfirmation,
    ListHeader,
    SearchField,
  },
  data: () => ({
    clientTable: {
      options: {
        itemsPerPage: 20,
      },
      totalItems: 0,
      search: '',
      selected: [],
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Slug', value: 'slug' },
        { text: 'Users', value: 'num_users', sortable: false },
        { text: 'Devices', value: 'num_devices', sortable: false },
        { text: 'Created', value: 'created_at' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      actions: [
        {
          text: 'Edit Client',
          icon: 'mdi-pencil',
          color: 'primary',
          permissions: ['core.change_client'],
          emit: 'edit',
        },
        {
          text: 'Delete Client',
          icon: 'mdi-trash-can',
          color: 'error',
          permissions: ['core.delete_client'],
          emit: 'confirm-delete',
        },
      ],
    },
  }),
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      clients: (state) => state.clients.all,
      dt: (state) => state.datatable,
    }),
  },
  mounted() {
    console.log(this.dt.search)
    // this.getClients()
    if (this.dt.search != '') {
      setTimeout(() => {
        this.updateSearch(this.dt.search)
      }, 100)
    }
  },
  methods: {
    hasPermission,
    getPaginationParams,
    localDatetime,
    ...mapActions('clients', {
      getClients: 'getAll',
      preDeleteClient: 'preDelete',
      deleteClient: 'delete',
    }),
    ...mapActions('datatable', {
      setSearch: 'setSearch',
      setColumn: 'setColumn',
      setOrder: 'setOrder',
      setPage: 'setPageNumber',
    }),
    editClient(item) {
      this.$router.push({ name: 'ChangeClient', params: { pk: item.pk } })
    },

    /** Pagination handlers **/
    getPaginatedClients() {
      const params = this.getPaginationParams(this.clientTable.options, this.clientTable.search)
      this.getClients(params).then((data) => {
        this.clientTable.totalPages = data.num_pages
        this.clientTable.totalItems = data.num_results
      })
    },
    updateSearch(value) {
      this.clientTable.search = value
      this.setSearch(value)
      this.getPaginatedClients()
    },
    sortTable(value) {
      if (value != undefined) {
        this.setColumn(value)
      } else {
        this.setColumn('')
      }
    },
    sortOrder(value) {
      if (value != undefined) {
        this.setOrder(value)
      } else {
        this.setOrder('')
      }
    },
    page(value) {
      this.setPage(value)
    },
  },
}
</script>
