import { List, Update, Create } from '@/views/client'

const routes = [
  {
    path: '/client',
    name: 'Client',
    component: List,
    meta: {
      public: false,
      permissions: ['core.view_client'],
    },
  },
  {
    path: '/client/add',
    name: 'AddClient',
    component: Create,
    meta: {
      public: false,
      permissions: ['core.add_client'],
    },
  },
  {
    path: '/client/:pk/change',
    name: 'ChangeClient',
    component: Update,
    meta: {
      public: false,
      permissions: ['core.change_client'],
    },
  },
]

export default routes

// export default routes.map((route) => {
//   const meta = {
//     public: false,
//   }
//   return {
//     ...route,
//     meta,
//   }
// })
