import http from '@/spaday/http-common'

class AuthService {
  //   csrf() {
  //     return http.get(`/csrf/`).then((token) => {
  //       return token
  //     })
  //   }

  login(email, password) {
    return http.post(`/auth/login/`, {
      email,
      password,
    })
  }

  logout() {
    return http.post(`/auth/logout/`)
  }

  refreshToken() {
    return http.post(`/auth/token/refresh/`, {
      refresh: sessionStorage.getItem('refresh_token'),
    })
  }

  register() {
    // TODO:
  }
}

export default new AuthService()
