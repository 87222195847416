<template>
  <div>
    <FormHeader
      title="Update User"
      :subtitle="object.email"
      btnLabel="Update User"
      :canChange="hasPermission('users.change_user')"
      :canDelete="hasPermission('users.delete_user')"
      @submit="submit"
      @delete="preDeleteUser(object)"
      :deleteLoading="$store.state.users.delete.loading"
      :saveLoading="$store.state.users.object.loading"
    />

    <v-form ref="form" :disabled="object.loading" :readonly="!hasPermission('users.change_user')">
      <v-card class="mb-4" :loading="object.loading">
        <v-tabs v-model="tab" grow icons-and-text slider-size="4">
          <v-tabs-slider></v-tabs-slider>
          <template v-for="(item, index) in tabs">
            <v-tab :key="index" :href="item.href">
              {{ item.text }}
              <v-icon>{{ item.icon }}</v-icon>
            </v-tab>
          </template>
        </v-tabs>

        <v-divider class="primary"></v-divider>

        <v-tabs-items v-model="tab">
          <v-tab-item value="general">
            <CardSubheader icon="mdi-login-variant" title="Login Credentials" />

            <v-card-text>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field v-model="object.email" label="Email" :rules="[rules.required]"></v-text-field>
                </v-col>
                <v-col cols="12" md="6" v-if="hasPermission('users.change_user')">
                  <ChangePassword :user="object" />
                  <p>
                    <small
                      >Raw passwords are not stored, so there is no way to see this user’s password, but you can change
                      the password.</small
                    >
                  </p>
                </v-col>
              </v-row>
            </v-card-text>

            <CardSubheader icon="mdi-account" title="Personal Info" />

            <v-card-text>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field v-model="object.first_name" label="First Name" :rules="[rules.required]"></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="object.last_name" label="Last Name" :rules="[rules.required]"></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-tab-item>

          <v-tab-item value="permissions">
            <CardSubheader icon="mdi-list-status" title="Status" />
            <v-card-text>
              <v-row>
                <v-col cols="12" md="4">
                  <v-checkbox
                    v-model="object.is_active"
                    label="Active"
                    hint="Designates whether this user should be treated as active. Unselect this instead of deleting accounts."
                    persistent-hint
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" md="4">
                  <v-checkbox
                    v-model="object.is_staff"
                    label="Staff Status"
                    hint="Designates whether the user can log into this admin site."
                    persistent-hint
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" md="4" v-if="user.is_superuser">
                  <v-checkbox
                    v-model="object.is_superuser"
                    label="Superuser Status"
                    hint="Designates that this user has all permissions without explicitly assigning them."
                    persistent-hint
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-card-text>

            <CardSubheader icon="mdi-account-group" title="Groups" />
            <v-card-text>
              <v-row>
                <v-col cols="12" md="6">
                  <v-select
                    label="Groups"
                    v-model="object.groups"
                    :items="groups.items"
                    :loading="groups.loading"
                    item-value="pk"
                    item-text="name"
                    multiple
                    clearable
                    small-chips
                    deletable-chips
                    hide-no-data
                    persistent-hint
                    hint="The groups this user belongs to. A user will get all permissions granted to each of their groups."
                  ></v-select>
                </v-col>
              </v-row>
            </v-card-text>

            <CardSubheader icon="mdi-key" title="Specific Permissions" />
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-select
                    label="Permissions"
                    v-model="object.user_permissions"
                    :items="permissions.items"
                    :loading="permissions.loading"
                    multiple
                    clearable
                    small-chips
                    deletable-chips
                    hide-no-data
                    persistent-hint
                    hint="Specific permissions for this user."
                  ></v-select>
                </v-col>
              </v-row>
            </v-card-text>
          </v-tab-item>
        </v-tabs-items>
        <v-divider></v-divider>
        <v-card-actions
          ><v-spacer></v-spacer>
          <div class="caption">
            User ID <strong>{{ object.pk }}</strong>
          </div></v-card-actions
        >
      </v-card>
    </v-form>

    <DeleteConfirmation @confirm-delete="deleteUserRedirect" />
  </div>
</template>

<script>
import { hasPermission } from '@/spaday/helpers'
import { mapState, mapActions } from 'vuex'
import { DeleteConfirmation, ChangePassword, FormHeader, CardSubheader } from '@/spaday/components'

export default {
  components: {
    DeleteConfirmation,
    ChangePassword,
    FormHeader,
    CardSubheader,
  },
  data: () => ({
    tabs: [
      {
        text: 'General Info',
        icon: 'mdi-information',
        href: '#general',
      },
      {
        text: 'Permissions',
        icon: 'mdi-key',
        href: '#permissions',
      },
    ],
    rules: {
      required: (value) => !!value || 'Required.',
    },
  }),
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      groups: (state) => state.groups.combobox,
      permissions: (state) => state.permissions.combobox,
      object: (state) => state.users.object,
    }),
    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } })
      },
      get() {
        return this.$route.query.tab
      },
    },
  },
  mounted() {
    this.getUser(this.$route.params.pk)
    this.getGroups()
    this.getPermissions()
  },
  beforeDestroy() {
    this.clearUser()
  },
  methods: {
    hasPermission,
    ...mapActions('groups', {
      getGroups: 'getCombobox',
    }),
    ...mapActions('permissions', {
      getPermissions: 'getCombobox',
    }),
    ...mapActions('users', {
      getUser: 'get',
      updateUser: 'update',
      preDeleteUser: 'preDelete',
      deleteUser: 'delete',
      clearUser: 'clear',
    }),
    submit() {
      if (!this.$refs.form.validate()) return

      this.updateUser({ id: this.object.pk, data: this.object }).then(() => {
        this.$router.go(-1)
      })
    },
    deleteUserRedirect(object) {
      this.deleteUser(object).then(() => {
        this.$router.go(-1)
      })
    },
  },
}
</script>
