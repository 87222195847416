import { Update, List } from '@/views/device'

const routes = [
  {
    path: '/device',
    name: 'Device',
    component: List,
    meta: {
      public: false,
      permissions: ['core.view_device'],
    },
  },
  //   {
  //     path: '/device/add',
  //     name: 'AddDevice',
  //     component: Create,
  //     meta: {
  //       public: false,
  //       permissions: ['core.add_device'],
  //     },
  //   },
  {
    path: '/device/:pk/change',
    name: 'ChangeDevice',
    component: Update,
    meta: {
      public: false,
      permissions: ['core.change_device'],
    },
  },
]

export default routes

// export default routes.map((route) => {
//   const meta = {
//     public: false,
//   }
//   return {
//     ...route,
//     meta,
//   }
// })
