import mqtt from 'mqtt'
import { MQTT_CONNECTION } from '@/constants'
import { MqttService } from '@/services'

const state = {
  details: {},
  client: {
    connected: false,
  },
}

const actions = {
  getConnectionDetails({ commit, state }) {
    console.log('Get MQTT Connection details')
    if (Object.keys(state.details).length !== 0) {
      // console.log('already have details')
      return
    }
    return MqttService.get().then(
      (data) => {
        commit('DETAILS_SUCCESS', data)
      },
      () => {
        // console.warn(error)
        // show toast dialog
        // let ctx = {
        //   text: 'Failed to retrieve MQTT connection details',
        //   color: 'error',
        // }
        // dispatch('toastDialog/show', ctx, { root: true })
      },
    )
  },

  createConnection({ commit, state }) {
    console.log('Create MQTT Connection')
    const { protocol, port, endpoint } = MQTT_CONNECTION
    const host = state.details.host
    const options = {
      ...MQTT_CONNECTION,
      username: state.details.username,
      password: state.details.password,
    }
    const client = mqtt.connect(`${protocol}://${host}:${port}${endpoint}`, options)
    console.log(`${protocol}://${host}:${port}${endpoint}`)

    client.on('error', (error) => {
      console.warn('Connection failed', error)
    })

    commit('CONNECT_SUCCESS', client)
    return client
  },

  destroyConnection({ commit, state }) {
    console.log('Destroy MQTT Connection')
    if (state.client.connected) {
      try {
        state.client.end()
        commit('DISCONNECT_SUCCESS')
      } catch (error) {
        console.log('Disconnect failed', error.toString())
      }
    }
  },
}

const mutations = {
  DETAILS_SUCCESS(state, data) {
    const creds = Buffer.from(data['creds'], 'base64').toString('utf8')

    state.details = {
      host: data['host'],
      username: creds.split('|')[0],
      password: creds.split('|')[1],
    }
  },

  CONNECT_SUCCESS(state, client) {
    state.client = client
  },

  DISCONNECT_SUCCESS(state) {
    state.client = {
      connected: false,
    }
  },
}

export const mqttClient = {
  namespaced: true,
  state,
  actions,
  mutations,
}
