<template>
  <v-icon :size="size" color="success" v-if="bool == true">mdi-check-circle</v-icon>
  <v-icon :size="size" color="error" v-else>mdi-close-circle</v-icon>
</template>

<script>
export default {
  props: {
    bool: { type: Boolean },
    size: { type: String, default: 'small' },
  },
}
</script>
