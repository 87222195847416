import { List, Update } from '@/spaday/views/auditlog'

const routes = [
  {
    path: '/auditlog',
    name: 'AuditLog',
    component: List,
    meta: {
      public: false,
      permissions: ['auditlog.view_logentry'],
    },
  },
  {
    path: '/auditlog/:pk/change',
    name: 'ChangeAuditLog',
    component: Update,
    meta: {
      public: false,
      permissions: ['auditlog.change_logentry'],
    },
  },
]

export default routes
