<template>
  <v-card class="mb-4" color="transparent">
    <v-card-text>
      <v-row align="center" justify="center">
        <!-- <v-icon x-large left>mdi-account</v-icon> -->
        <v-col>
          <v-card-title class="text--primary">{{ title }}</v-card-title>
          <v-card-subtitle><slot name="subtitle-prepend"></slot> {{ subtitle }}</v-card-subtitle>
        </v-col>
        <v-col class="text-right">
          <v-btn class="ma-2" small fab depressed icon outlined @click="$router.go(-1)" :disabled="saveLoading"
            ><v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-btn
            class="ma-2"
            small
            fab
            depressed
            icon
            outlined
            v-if="canDelete"
            @click="$emit('delete')"
            color="error"
            :loading="deleteLoading"
            :disabled="saveLoading"
            ><v-icon>mdi-trash-can-outline</v-icon></v-btn
          >
          <v-btn :loading="saveLoading" v-if="canChange || canAdd" @click="$emit('submit')" color="success" x-large
            ><v-icon left>mdi-check</v-icon> {{ btnLabel }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row v-if="saveLoading">
        <v-col>
          <UploadProgress />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import UploadProgress from '@/spaday/components/UploadProgress'

export default {
  components: {
    UploadProgress,
  },
  props: {
    title: { type: String },
    subtitle: { type: String },
    btnLabel: { type: String },

    canAdd: { type: Boolean, default: false },
    canChange: { type: Boolean, default: false },
    canDelete: { type: Boolean, default: false },

    deleteLoading: { type: Boolean, default: false },
    saveLoading: { type: Boolean, default: false },
  },
}
</script>
