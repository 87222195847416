<template>
  <div>
    <v-card-title>
      <v-text-field
        v-model="table.search"
        prepend-inner-icon="mdi-magnify"
        label="Search Client Users..."
        outlined
        hide-details
        dense
      ></v-text-field>
      <v-spacer></v-spacer>
      <SearchUsers :loading="users.loading" :items="allUsers.items" @selected="addUsersToGroup" />
    </v-card-title>
    <v-data-table
      item-key="email"
      loading-text="Loading. Please wait..."
      :loading="users.loading"
      :items="users.items"
      :headers="table.headers"
      :search="table.search"
    >
      <template v-slot:item.actions="{ item }">
        <RowActions
          :actions="table.actions"
          @edit="editUser(item)"
          @remove-group-user="removeUsersFromGroup([item.pk])"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { hasPermission } from '@/spaday/helpers'
import { mapState, mapActions } from 'vuex'
import { RowActions } from '@/spaday/components'
import SearchUsers from '@/spaday/components/dialogs/SearchUsers'

export default {
  components: {
    RowActions,
    SearchUsers,
  },
  data: () => ({
    table: {
      search: '',
      headers: [
        { text: 'First Name', value: 'first_name' },
        { text: 'Last Name', value: 'last_name' },
        { text: 'Email', value: 'email' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      actions: [
        {
          text: 'Edit User',
          icon: 'mdi-pencil',
          color: 'primary',
          permissions: ['users.change_user'],
          emit: 'edit',
        },
        {
          text: 'Remove User from Group',
          icon: 'mdi-account-remove',
          color: 'warning',
          permissions: ['auth.change_group'],
          emit: 'remove-group-user',
        },
      ],
    },
  }),
  computed: {
    ...mapState({
      object: (state) => state.clients.object,
      users: (state) => state.clients.users,
      allUsers: (state) => state.users.all,
    }),
  },
  mounted() {
    this.getUsers()
    this.getGroupUsers(this.$route.params.pk)
  },
  beforeDestroy() {},
  methods: {
    hasPermission,
    ...mapActions('users', {
      getUsers: 'getAll',
    }),
    ...mapActions('clients', {
      getGroupUsers: 'getUsers',
      addGroupUsers: 'addUsers',
      removeGroupUsers: 'removeUsers',
    }),

    editUser(item) {
      this.$router.push({ name: 'ChangeUser', params: { pk: item.pk } })
    },

    addUsersToGroup(items) {
      let ids = []
      items.forEach(function (item) {
        ids.push(item.pk)
      })
      this.addGroupUsers({ id: this.$route.params.pk, data: ids }).then(() => {
        this.getGroupUsers(this.$route.params.pk)
      })
    },
    removeUsersFromGroup(ids) {
      this.removeGroupUsers({ id: this.$route.params.pk, data: ids })
    },
  },
}
</script>
