import Vue from 'vue'
import Vuex from 'vuex'

import { VERSION, PROJECT_NAME } from '@/constants'

import {
  alert,
  account,
  users,
  groups,
  permissions,
  uploadProgress,
  auditlog,
  deleteDialog,
  toastDialog,
  taskresults,
} from '@/spaday/store'

// custom modules
import { mqttClient } from './mqtt.module'
import { clients } from './clients.module'
import { preferences } from './preferences.module'
import { terminalcommands } from './terminalcommands.module'
import { operatingsystems } from './operatingsystem.module'
import { devices } from './devices.module'
import { datatable } from './data-table.module'
import { tags } from './tags.module'

import LogRocket from 'logrocket'
import createPlugin from 'logrocket-vuex'

const logrocketPlugin = createPlugin(LogRocket)

Vue.use(Vuex)

export default new Vuex.Store({
  // strict: true,
  modules: {
    alert,
    deleteDialog,
    toastDialog,
    account,
    users,
    groups,
    permissions,
    uploadProgress,
    auditlog,
    taskresults,
    // custom modules
    clients,
    mqttClient,
    preferences,
    terminalcommands,
    operatingsystems,
    devices,
    datatable,
    tags,
    plugins: [logrocketPlugin],
  },
  state: {
    projectName: PROJECT_NAME,
    version: VERSION,
  },
  mutations: {},
})
