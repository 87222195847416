import http from '@/spaday/http-common'

class MqttService {
  /** Base CRUD REST calls **/
  get(params = {}) {
    return http.get('/mqtt/', { params })
  }
}

export default new MqttService()
