import { PermissionService } from '@/services'

const state = {
  combobox: {},
}

const actions = {
  getCombobox({ commit }) {
    commit('GET_COMBOBOX_REQUEST')

    return PermissionService.combobox().then(
      (data) => commit('GET_COMBOBOX_SUCCESS', data),
      (error) => commit('GET_COMBOBOX_FAILURE', error),
    )
  },
}

const mutations = {
  GET_COMBOBOX_REQUEST(state) {
    state.combobox = {
      loading: true,
    }
  },

  GET_COMBOBOX_SUCCESS(state, data) {
    state.combobox = {
      items: data,
    }
  },

  GET_COMBOBOX_FAILURE(state, error) {
    state.combobox = {
      error,
    }
  },
}

export const permissions = {
  namespaced: true,
  state,
  actions,
  mutations,
}
