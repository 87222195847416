<template>
  <v-alert v-if="alert.message" :type="alert.type" dismissible>{{ alert.message }}</v-alert>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
}
</script>
