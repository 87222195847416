<template>
  <div>
    <FormHeader
      title="Update Terminal Command"
      :subtitle="object.label"
      btnLabel="Update Terminal Command"
      :canChange="hasPermission('core.change_terminalcommand')"
      :canDelete="hasPermission('core.delete_terminalcommand')"
      @submit="submit"
      @delete="preDeleteTerminalCommand(object)"
      :deleteLoading="$store.state.terminalcommands.delete.loading"
      :saveLoading="$store.state.terminalcommands.object.loading"
    />

    <v-form ref="form" :disabled="object.loading" :readonly="!hasPermission('core.change_terminalcommand')">
      <v-card class="mb-4" :loading="object.loading">
        <v-toolbar dark flat dense color="primary">
          <v-icon left>mdi-information-variant</v-icon>
          <v-toolbar-title> General Info </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-select
                v-model="object.os_value"
                label="Operating System"
                :items="operatingSystems.items"
                :loading="operatingSystems.loading"
                item-text="text"
                item-value="value"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field v-model="object.label" label="Label" :rules="[rules.required]"></v-text-field>
            </v-col>
            <v-col cols="12" md="9">
              <v-text-field v-model="object.value" label="Value" :rules="[rules.required]"></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-checkbox
                v-model="object.published"
                label="Published"
                hint="Only published commands will be available."
                persistent-hint
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>

    <DeleteConfirmation @confirm-delete="deleteRedirect" />
  </div>
</template>

<script>
import { hasPermission } from '@/spaday/helpers'
import { mapState, mapActions } from 'vuex'
import { FormHeader, DeleteConfirmation } from '@/spaday/components'

export default {
  components: {
    DeleteConfirmation,
    FormHeader,
  },
  data: () => ({
    rules: {
      required: (value) => !!value || 'Required.',
      min: (v) => v.length >= 8 || 'At least 8 characters',
    },
  }),
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      object: (state) => state.terminalcommands.object,
      operatingSystems: (state) => state.operatingsystems.combobox,
    }),
  },
  mounted() {
    this.getTerminalCommand(this.$route.params.pk)
    this.getOperatingSystems()
  },
  methods: {
    hasPermission,
    ...mapActions('operatingsystems', {
      getOperatingSystems: 'getCombobox',
    }),
    ...mapActions('terminalcommands', {
      getTerminalCommand: 'get',
      updateTerminalCommand: 'update',
      preDeleteTerminalCommand: 'preDelete',
      deleteTerminalCommand: 'delete',
      clearTerminalCommand: 'clear',
    }),
    submit() {
      if (!this.$refs.form.validate()) return

      this.updateTerminalCommand({ id: this.object.pk, data: this.object }).then(() => {
        this.$router.go(-1)
      })
    },
    deleteRedirect(object) {
      this.deleteTerminalCommand(object).then(() => {
        this.$router.go(-1)
      })
    },
  },
}
</script>
