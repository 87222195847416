import { List, Update, Create } from '@/views/terminalcommand'
const routes = [
  {
    path: '/terminal-command',
    name: 'TerminalCommand',
    component: List,
    meta: {
      public: false,
      permissions: ['core.view_terminalcommand'],
    },
  },
  {
    path: '/terminal-command/add',
    name: 'AddTerminalCommand',
    component: Create,
    meta: {
      public: false,
      permissions: ['core.add_terminalcommand'],
    },
  },
  {
    path: '/terminal-command/:pk/change',
    name: 'ChangeTerminalCommand',
    component: Update,
    meta: {
      public: false,
      permissions: ['core.change_terminalcommand'],
    },
  },
]

export default routes

// export default routes.map((route) => {
//   const meta = {
//     public: false,
//   }
//   return {
//     ...route,
//     meta,
//   }
// })
