import { List, Update } from '@/spaday/views/taskresult'

const routes = [
  {
    path: '/taskresults',
    name: 'TaskResult',
    component: List,
    meta: {
      public: false,
      permissions: ['django_celery_results.view_taskresult'],
    },
  },
  {
    path: '/taskresults/:pk/change',
    name: 'ChangeTaskResult',
    component: Update,
    meta: {
      public: false,
      permissions: ['django_celery_results.change_taskresult'],
    },
  },
]

export default routes
