import axios from 'axios'

export const forceFileDownload = (response, title) => {
  const url = window.URL.createObjectURL(new Blob([response.data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', title)
  document.body.appendChild(link)
  link.click()
}

export const downloadWithAxios = (url, filename = null) => {
  if (filename == null) {
    filename = url.substring(url.lastIndexOf('/') + 1)
  }

  axios({
    method: 'GET',
    url: `${url}?no-cache`,
    responseType: 'blob',
    // crossdomain: true
  }).then((response) => {
    forceFileDownload(response, filename)
  })
  // .catch((e) => {
  //   console.error(String(e))
  // })
}
