<template>
  <v-toolbar flat dense>
    <v-icon small v-if="icon" left>{{ icon }}</v-icon>
    <v-toolbar-title class="text-md-subtitle-1">{{ title }} </v-toolbar-title>
  </v-toolbar>
</template>

<script>
export default {
  props: {
    title: { type: String },
    icon: { type: String },
  },
}
</script>
