<template>
  <div>
    <FormHeader
      title="Create Client"
      :subtitle="object.name"
      btnLabel="Save Client"
      :canAdd="hasPermission('core.add_client')"
      @submit="submit"
      :saveLoading="$store.state.clients.object.loading"
    />

    <v-form ref="form">
      <v-card>
        <v-toolbar dark flat dense color="primary">
          <v-icon left>mdi-account</v-icon>
          <v-toolbar-title> Client </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field v-model="object.name" label="Name" :rules="[rules.required]"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { hasPermission } from '@/spaday/helpers'
import { FormHeader } from '@/spaday/components'
import { mapActions } from 'vuex'

export default {
  components: {
    FormHeader,
  },
  data: () => ({
    object: {},
    rules: {
      required: (value) => !!value || 'Required.',
      min: (v) => v.length >= 8 || 'At least 8 characters',
    },
  }),
  methods: {
    hasPermission,
    ...mapActions('clients', {
      createClient: 'create',
    }),
    submit() {
      if (!this.$refs.form.validate()) return

      this.createClient(this.object).then(() => {
        this.$router.go(-1)
      })
    },
  },
}
</script>
