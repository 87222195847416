<template>
  <v-card class="flex">
    <v-toolbar dark flat dense color="primary">
      <slot name="toolbar">
        <v-icon v-if="icon" left>{{ icon }}</v-icon>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </slot>
    </v-toolbar>
    <!-- <v-card-text> -->
    <v-data-table hide-default-footer disable-sort :headers="headers" :items="items">
      <template v-slot:[`item.percentage`]="{ item }">
        <v-progress-linear height="20" :value="item.percentage" striped rounded color="secondary"
          ><template v-slot:default>
            <strong>{{ item.percentage }}%</strong>
          </template>
        </v-progress-linear>
      </template>
    </v-data-table>
    <!-- </v-card-text> -->
    <slot name="append"></slot>
  </v-card>
</template>

<script>
export default {
  props: {
    icon: { type: String },
    title: { type: String },
    items: { type: Array },
    headers: { type: Array },
  },
}
</script>
