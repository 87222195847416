const state = {
  show: false,
  color: null,
  icon: null,
  title: null,
  text: null,
  position: 'top',
  timeout: 0,
}

const actions = {
  show({ commit }, payload) {
    commit('SHOW', payload)
  },
  clear({ commit }) {
    console.log('toastdialog.module:: clear')
    commit('CLEAR')
  },
}

const mutations = {
  SHOW(state, payload) {
    state.show = true
    state.color = payload.color
    state.title = payload.title ? payload.title : null
    state.text = payload.text
  },
  CLEAR(state) {
    state.show = false
    state.title = null
    state.text = null
  },
}

export const toastDialog = {
  namespaced: true,
  state,
  actions,
  mutations,
}
