<template>
  <div>
    <FormHeader
      title="Create Group"
      :subtitle="object.name"
      btnLabel="Save Group"
      :canAdd="hasPermission('auth.add_group')"
      @submit="submit"
      :saveLoading="$store.state.groups.object.loading"
    />

    <v-form ref="form" :disabled="object.loading">
      <v-card class="mb-4">
        <v-tabs v-model="tab" grow icons-and-text slider-size="4">
          <v-tabs-slider></v-tabs-slider>
          <template v-for="(item, index) in tabs">
            <v-tab :key="index" :href="item.href">
              {{ item.text }}
              <v-icon>{{ item.icon }}</v-icon>
            </v-tab>
          </template>
        </v-tabs>

        <v-divider class="primary"></v-divider>

        <v-tabs-items v-model="tab">
          <v-tab-item value="general">
            <v-card-text>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field v-model="object.name" label="Name" :rules="[rules.required]"></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-tab-item>

          <v-tab-item value="permissions">
            <v-card-text>
              <v-row>
                <v-col cols="12" md="12">
                  <v-select
                    v-model="object.permissions"
                    :items="permissions.items"
                    :loading="permissions.loading"
                    label="Permissions"
                    multiple
                    persistent-hint
                    hint="Specific permissions for this group. User specific permissions will always take precedence."
                    clearable
                    small-chips
                    deletable-chips
                    hide-no-data
                  ></v-select>
                </v-col>
              </v-row>
            </v-card-text>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { hasPermission } from '@/spaday/helpers'
import { mapState, mapActions } from 'vuex'
import { FormHeader } from '@/spaday/components'

export default {
  components: {
    FormHeader,
  },
  data: () => ({
    object: {
      permissions: [],
    },
    tabs: [
      {
        text: 'General Info',
        icon: 'mdi-information',
        href: '#general',
      },
      {
        text: 'Permissions',
        icon: 'mdi-key',
        href: '#permissions',
      },
    ],
    rules: {
      required: (value) => !!value || 'Required.',
    },
  }),
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      permissions: (state) => state.permissions.combobox,
    }),
    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } })
      },
      get() {
        return this.$route.query.tab
      },
    },
  },
  mounted() {
    this.getPermissions()
  },
  methods: {
    hasPermission,
    ...mapActions('permissions', {
      getPermissions: 'getCombobox',
    }),
    ...mapActions('groups', {
      createGroup: 'create',
    }),
    submit() {
      if (!this.$refs.form.validate()) return

      this.createGroup(this.object).then(() => {
        this.$router.go(-1)
      })
    },
  },
}
</script>
