<template>
  <div>
    <canvas id="chart"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js'

export default {
  props: ['chartdata'],
  mounted() {
    const ctx = document.getElementById('chart')
    new Chart(ctx, this.chartdata)
  },
  watch: {
    chartdata: function (newVal) {
      // console.log('Prop changed: ', newVal, ' | was: ', oldVal)
      const ctx = document.getElementById('chart')
      new Chart(ctx, newVal)
    },
  },
}
</script>
