const state = {
  name: null,
  pk: null,
  model: null,
  protected: [],
  modelCount: [],
  deletedObjects: [],
  show: false,
}

const actions = {
  confirm({ commit }, payload) {
    commit('CONFIRM', payload)
  },
  clear({ commit }) {
    commit('CLEAR')
  },
}

const mutations = {
  CONFIRM(state, payload) {
    state.pk = payload.pk
    state.name = payload.name
    state.model = payload.model
    state.protected = payload.protected
    state.modelCount = payload.model_count
    state.deletedObjects = payload.deleted_objects
    state.show = true
  },
  CLEAR(state) {
    state.pk = null
    state.name = null
    state.model = null
    state.protected = []
    state.modelCount = []
    state.deletedObjects = []
    state.show = false
  },
}

export const deleteDialog = {
  namespaced: true,
  state,
  actions,
  mutations,
}
