<template>
  <span>
    <v-icon left v-if="os == 'windows10'">mdi-microsoft-windows</v-icon>
    <v-icon left v-else-if="os == 'macosx'">mdi-apple</v-icon>
    <v-icon left v-else-if="os == 'raspbian'">mdi-raspberry-pi</v-icon>
    <v-icon left v-else>mdi-all-inclusive</v-icon> {{ display_name }}
  </span>
</template>

<script>
export default {
  name: 'PlatformIcon',
  props: {
    os: { type: String },
    display_name: { type: String },
  },
}
</script>
