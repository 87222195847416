<template>
  <v-app>
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-12 mb-4">
              <v-toolbar dark flat color="primary">
                <v-img src="@/assets/xstudios-logo-white.svg" contain max-height="25"></v-img>
              </v-toolbar>
              <v-form ref="form">
                <v-card-text>
                  <Alert />

                  <v-text-field
                    v-model="first_name"
                    name="first_name"
                    label="First Name"
                    type="text"
                    :rules="[rules.required]"
                    required
                  ></v-text-field>

                  <v-text-field
                    v-model="last_name"
                    name="last_name"
                    label="Last Name"
                    type="text"
                    :rules="[rules.required]"
                    required
                  ></v-text-field>

                  <v-text-field
                    prepend-inner-icon="mdi-email"
                    v-model="email"
                    name="email"
                    label="Email"
                    type="text"
                    :rules="[rules.required]"
                    required
                  ></v-text-field>

                  <v-text-field
                    prepend-inner-icon="mdi-lock"
                    :type="showPass ? 'text' : 'password'"
                    v-model="password"
                    :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPass = !showPass"
                    label="Password"
                    :rules="[rules.required, rules.min]"
                    persistent-hint
                    hint="At least 8 characters"
                    counter
                  ></v-text-field>

                  <v-text-field
                    prepend-inner-icon="mdi-lock"
                    :type="showPass ? 'text' : 'password'"
                    v-model="password_confirm"
                    :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPass = !showPass"
                    label="Password Confirmation"
                    :rules="[confirmPassword]"
                    persistent-hint
                    hint="Enter the same password as before, for verification."
                  ></v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="submit" color="primary" block x-large> Create Account </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
            <div class="text-center caption">
              Already have an account? <router-link :to="{ name: 'Login' }">Sign In</router-link>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
// import { mapState, mapActions } from 'vuex'
import { Alert } from '@/spaday/components'

export default {
  name: 'Register',
  components: {
    Alert,
  },
  data: () => ({
    showPass: false,
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    password_confirm: '',
    rules: {
      required: (value) => !!value || 'Required.',
    },
  }),
  //   computed: {
  //     ...mapState('account', ['status', 'user']),
  //   },
  //   mounted() {
  //       // if logged in already, kick back to dashboard
  //       if (this.status.loggedIn && this.user) {
  //           this.$router.push({ name: 'Dashboard', params: {} })
  //       }
  //   },
  methods: {
    // ...mapActions('account', ['login']),
    confirmPassword() {
      return this.password === this.password_confirm || 'Password must match'
    },
    submit() {
      if (!this.$refs.form.validate()) return

      const { email, password } = this
      if (email && password) {
        // this.login({ email, password })
      }
    },
    clear() {
      this.$refs.form.reset()
    },
  },
}
</script>
