<template>
  <v-snackbar v-model="toastDialog.show" :color="toastDialog.color" multi-line :top="toastDialog.position === 'top'">
    <v-layout align-center pr-4>
      <v-icon class="pr-3" dark large>{{ icon }}</v-icon>
      <v-layout column>
        <div v-if="toastDialog.title">
          <strong>{{ toastDialog.title }}</strong>
        </div>
        <div v-html="toastDialog.text"></div>
      </v-layout>
    </v-layout>
  </v-snackbar>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      toastDialog: (state) => state.toastDialog,
    }),
    icon() {
      switch (this.toastDialog.color) {
        case 'success':
          return 'mdi-check-circle'
        case 'error':
          return 'mdi-close-circle'
        case 'warning':
          return 'mdi-alert'
        case 'info':
          return 'mdi-information'
        default:
          return 'mdi-check-circle'
      }
    },
  },
  methods: {
    ...mapActions('toastDialog', {
      clear: 'clear',
    }),
    hide() {
      this.clear()
    },
  },
}
</script>
