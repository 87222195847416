import { AuthService } from '@/services'
import router from '@/router'

const loggedIn = sessionStorage.getItem('loggedIn')
const user = sessionStorage.getItem('user')

const state = {
  status: {
    loggedIn: loggedIn ? true : false,
  },
  access_token: '',
  refresh_token: '',
  user: JSON.parse(user),
  // csrf: '',
}

const actions = {
  login({ commit }, { email, password }) {
    return AuthService.login(email, password).then(
      (data) => {
        commit('LOGIN_SUCCESS', data)
        router.push({
          name: 'Dashboard',
        })
        // AuthService.csrf().then((token) => {
        //   commit('CSRF_SUCCESS', token)
        //   router.push({
        //     name: 'Dashboard',
        //   })
        // })
      },
      (error) => {
        commit('LOGIN_FAILURE', error)
        // dispatch('alert/error', error, {
        //   root: true,
        // })
      },
    )
  },

  logout({ commit }) {
    AuthService.logout()
      .then(() => {
        commit('LOGOUT')
        router.push({
          name: 'Login',
        })
      })
      .catch((error) => {
        console.log('Logout error:', error)
        commit('LOGOUT')
        // router.push({
        //   name: 'Login',
        // })
      })
    console.log('LOGGED OUT')
  },

  //   register({ dispatch, commit }, user) {
  //     commit('REGISTER_REQUEST', user)

  //     AuthService.register(user).then(
  //       (user) => {
  //         commit('REGISTER_SUCCESS', user)
  //         router.push('/login')
  //         setTimeout(() => {
  //           // display success message after route change completes
  //           dispatch('alert/success', 'Registration successful', {
  //             root: true,
  //           })
  //         })
  //       },
  //       (error) => {
  //         commit('REGISTER_FAILURE', error)
  //         dispatch('alert/error', error, {
  //           root: true,
  //         })
  //       },
  //     )
  //   },
}

const mutations = {
  //   LOGIN_REQUEST(state, user) {
  //     state.status = {
  //       loggingIn: true,
  //     }
  //   },

  LOGIN_SUCCESS(state, data) {
    sessionStorage.setItem('loggedIn', true)
    sessionStorage.setItem('access_token', data.access)
    sessionStorage.setItem('refresh_token', data.refresh)
    sessionStorage.setItem('user', JSON.stringify(data.user))
    state.user = data.user
  },

  LOGIN_FAILURE(state) {
    state.status = {
      loggedIn: false,
    }

    state.user = null
  },

  LOGOUT(state) {
    state.status = {
      loggedIn: false,
    }

    state.access_token = null
    state.refresh_token = null
    state.user = null

    sessionStorage.removeItem('loggedIn')
    sessionStorage.removeItem('access_token')
    sessionStorage.removeItem('refresh_token')
    sessionStorage.removeItem('user')
  },

  //   CSRF_SUCCESS(state, token) {
  //     state.csrf = token
  //   },

  //   CSRF_FAILURE(state) {
  //     state.csrf = null
  //   },

  //   REGISTER_REQUEST(state, user) {
  //     console.log('REGISTER_REQUEST', user)
  //     state.status = {
  //       registering: true,
  //     }
  //   },

  REGISTER_SUCCESS(state) {
    state.status = {
      loggedIn: true,
    }
  },

  REGISTER_FAILURE(state) {
    state.status = {
      loggedIn: false,
    }
  },
}

export const account = {
  namespaced: true,
  state,
  actions,
  mutations,
}
