<template>
  <v-list dense nav>
    <slot name="prepend"></slot>

    <template v-for="(item, index) in items">
      <v-list-item
        v-if="item.text && hasAnyPermission(item.permissions)"
        :key="`${item.text + index}`"
        link
        @click="$emit(item.emit)"
      >
        <v-list-item-icon>
          <v-icon v-text="item.icon" :color="item.color"></v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="item.text"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider v-else-if="item.divider" :key="index" class="mb-1"></v-divider>

      <v-subheader v-else-if="item.header" :key="`${item.header + index}`">
        {{ item.header }}
      </v-subheader>
    </template>

    <slot name="append"></slot>
  </v-list>
</template>

<script>
import { hasAnyPermission } from '@/spaday/helpers'

export default {
  props: {
    items: { type: Array },
  },
  methods: {
    hasAnyPermission,
  },
}
</script>
