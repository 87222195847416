import { getDeleteMessage, getChangeMessage, getAddMessage } from '@/spaday/helpers'
import { UserService } from '@/services'

const state = {
  all: {},
  object: {},
  delete: {},
  recentLogins: {},
}

const MODEL_NAME = 'user'

const actions = {
  getAll({ commit }, params) {
    commit('GET_ALL_REQUEST')

    return UserService.getAll(params).then(
      (data) => {
        commit('GET_ALL_SUCCESS', data)
        return data
      },
      (error) => {
        commit('GET_ALL_FAILURE', error)
      },
    )
  },

  get({ commit }, id) {
    commit('GET_REQUEST')

    return UserService.get(id).then(
      (data) => {
        commit('GET_SUCCESS', data)
      },
      (error) => {
        commit('GET_FAILURE', error)
      },
    )
  },

  create({ dispatch, commit }, data) {
    commit('CREATE_REQUEST')

    return UserService.create(data).then(
      (data) => {
        commit('CREATE_SUCCESS', data)
        // show toast dialog
        let ctx = {
          text: getAddMessage(MODEL_NAME, `${data.email}`),
          color: 'success',
        }
        dispatch('toastDialog/show', ctx, { root: true })
      },
      (error) => {
        commit('CREATE_FAILURE', error)
      },
    )
  },

  update({ dispatch, commit }, { id, data }) {
    commit('UPDATE_REQUEST')

    return UserService.update(id, data).then(
      (data) => {
        commit('UPDATE_SUCCESS', data)
        // show toast dialog
        let ctx = {
          text: getChangeMessage(MODEL_NAME, `${data.email}`),
          color: 'success',
        }
        dispatch('toastDialog/show', ctx, { root: true })
      },
      (error) => {
        commit('UPDATE_FAILURE', error)
      },
    )
  },

  preDelete({ dispatch, commit }, object) {
    commit('PRE_DELETE_REQUEST')

    return UserService.preDelete(object.pk).then(
      (data) => {
        commit('PRE_DELETE_SUCCESS')
        // show delete confirmation dialog
        data.pk = object.pk
        data.name = `${object.email}`
        dispatch('deleteDialog/confirm', data, { root: true })
      },
      (error) => {
        commit('PRE_DELETE_FAILURE', error)
      },
    )
  },

  delete({ dispatch, commit }, object) {
    commit('DELETE_REQUEST')

    return UserService.delete(object.pk).then(
      () => {
        commit('DELETE_SUCCESS', object.pk)
        // show toast dialog
        let ctx = {
          text: getDeleteMessage(MODEL_NAME, `${object.name}`),
          color: 'success',
        }
        dispatch('toastDialog/show', ctx, { root: true })
      },
      (error) => {
        commit('DELETE_FAILURE', error)
      },
    )
  },

  clear({ commit }) {
    commit('CLEAR')
  },

  getRecentLogins({ commit }, id) {
    commit('GET_RECENT_LOGIN_REQUEST')

    return UserService.recentLogins(id).then(
      (data) => commit('GET_RECENT_LOGIN_SUCCESS', data),
      (error) => commit('GET_RECENT_LOGIN_FAILURE', error),
    )
  },

  changePassword({ dispatch, commit }, { id, data }) {
    // commit('CHANGE_PASSWORD_REQUEST')

    return UserService.changePassword(id, data).then(
      (data) => {
        // commit('CHANGE_PASSWORD_SUCCESS', data)
        // show toast dialog
        let ctx = {
          text: `Password changed successfully.`,
          color: 'success',
        }
        dispatch('toastDialog/show', ctx, { root: true })
        return data
      },
      (error) => {
        commit('UPDATE_FAILURE', error)
      },
    )
  },
}

const mutations = {
  GET_ALL_REQUEST(state) {
    state.all = {
      loading: true,
    }
  },

  GET_ALL_SUCCESS(state, data) {
    state.all = {
      items: data.results,
    }
  },

  GET_ALL_FAILURE(state, error) {
    state.all = {
      error,
    }
  },

  GET_REQUEST(state) {
    state.object = {
      loading: true,
    }
  },

  GET_SUCCESS(state, data) {
    state.object = data
  },

  GET_FAILURE(state, error) {
    state.object = {
      error,
    }
  },

  CREATE_REQUEST(state) {
    state.object = {
      loading: true,
    }
  },

  CREATE_SUCCESS(state, data) {
    state.object = data
  },

  CREATE_FAILURE(state, error) {
    state.object = {
      error,
    }
  },

  UPDATE_REQUEST(state) {
    state.object = {
      loading: true,
    }
  },

  UPDATE_SUCCESS(state, data) {
    state.object = data
  },

  UPDATE_FAILURE(state, error) {
    state.object = {
      error,
    }
  },

  CLEAR(state) {
    state.object = {}
  },

  PRE_DELETE_REQUEST(state) {
    state.delete = {
      loading: true,
    }
  },

  PRE_DELETE_SUCCESS(state) {
    state.delete = {}
  },

  PRE_DELETE_FAILURE(state, error) {
    state.delete = {
      error,
    }
  },

  DELETE_REQUEST(state) {
    state.delete = {
      loading: true,
    }
  },

  DELETE_SUCCESS(state, id) {
    state.delete = {}

    // remove deleted object from state
    if (state.all.items) {
      state.all.items = state.all.items.filter((object) => object.pk !== id)
    }
  },

  DELETE_FAILURE(state, error) {
    state.delete = {
      error,
    }
  },

  /** Users Recent Logins **/
  GET_RECENT_LOGIN_REQUEST(state) {
    state.recentLogins = {
      loading: true,
    }
  },

  GET_RECENT_LOGIN_SUCCESS(state, data) {
    state.recentLogins = {
      items: data,
    }
  },

  GET_RECENT_LOGIN_FAILURE(state, error) {
    state.recentLogins = {
      error,
    }
  },
}

export const users = {
  namespaced: true,
  state,
  actions,
  mutations,
}
