<template>
  <div>
    <ListHeader title="Celery Task Results" subtitle="View task results." />

    <v-card>
      <v-card-title class="text--primary">
        <SearchField label="Search Task Results..." @search="updateSearch" />
      </v-card-title>
      <v-data-table
        v-model="taskTable.selected"
        item-key="pk"
        loading-text="Loading. Please wait..."
        :loading="taskresults.loading"
        :items="taskresults.items"
        :headers="taskTable.headers"
        :search="taskTable.search"
        :footer-props="{
          'items-per-page-options': [10, 20, 50, 100],
          'show-current-page': true,
        }"
        :options.sync="taskTable.options"
        :server-items-length="taskTable.totalItems"
        @update:options="getPaginatedTasks"
      >
        <template v-slot:item.status="{ item }">
          <v-chip class="action-chip" small :color="statusColor(item.status)">{{ item.status }}</v-chip>
        </template>
        <template v-slot:item.model="{ item }">
          {{ upperCaseFirst(item.model) }}
        </template>
        <template v-slot:item.date_done="{ item }">
          {{ localDatetime(item.date_done) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <RowActions
            :actions="taskTable.actions"
            @edit="editTaskResult(item)"
            @confirm-delete="preDeleteTaskResult(item)"
          />
        </template>
      </v-data-table>
    </v-card>

    <DeleteConfirmation @confirm-delete="deleteTaskResult" />
  </div>
</template>

<script>
import { localDatetime, hasPermission, upperCaseFirst, getPaginationParams } from '@/spaday/helpers'
import { mapState, mapActions } from 'vuex'
import { ListHeader, RowActions, DeleteConfirmation, SearchField } from '@/spaday/components'

export default {
  components: {
    RowActions,
    DeleteConfirmation,
    ListHeader,
    SearchField,
  },
  data: () => ({
    taskTable: {
      options: {
        itemsPerPage: 20,
      },
      totalItems: 0,
      search: '',
      selected: [],
      headers: [
        // { text: 'Task ID', value: 'task_id', sortable: false },
        { text: 'Periodic Task Name', value: 'periodic_task_name' },
        { text: 'Task Name', value: 'task_name' },
        { text: 'Status', value: 'status' },
        { text: 'Completed', value: 'date_done' },
        { text: 'Worker', value: 'worker' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      actions: [
        {
          text: 'View Task Result',
          icon: 'mdi-eye',
          color: 'primary',
          permissions: ['django_celery_results.change_taskresult'],
          emit: 'edit',
        },
        {
          text: 'Delete Task Result',
          icon: 'mdi-trash-can-outline',
          color: 'error',
          permissions: ['django_celery_results.delete_taskresult'],
          emit: 'confirm-delete',
        },
      ],
    },
  }),
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      taskresults: (state) => state.taskresults.all,
    }),
  },
  mounted() {
    // this.getTaskResults()
  },
  methods: {
    hasPermission,
    localDatetime,
    getPaginationParams,
    upperCaseFirst,
    ...mapActions('taskresults', {
      getTaskResults: 'getAll',
      preDeleteTaskResult: 'preDelete',
      deleteTaskResult: 'delete',
    }),
    editTaskResult(item) {
      this.$router.push({ name: 'ChangeTaskResult', params: { pk: item.pk } })
    },
    statusColor(action) {
      if (action === 'FAILURE') {
        return 'error'
      } else if (action === 'SUCCESS') {
        return 'success'
      }
      return 'warning'
    },

    /** Pagination handlers **/
    getPaginatedTasks() {
      const params = this.getPaginationParams(this.taskTable.options, this.taskTable.search)
      this.getTaskResults(params).then((data) => {
        this.taskTable.totalPages = data.num_pages
        this.taskTable.totalItems = data.num_results
      })
    },
    updateSearch(value) {
      this.taskTable.search = value
      this.getPaginatedTasks()
    },
  },
}
</script>

<style scoped>
.action-chip {
  display: block;
  text-align: center;
}
</style>
