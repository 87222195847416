import axios from 'axios'
import store from '@/store'
import { AuthService } from '@/services'
import { API_URL } from '@/constants'
import { getCsrfToken } from '@/spaday/helpers/csrf'

const getApiUrl = () => {
  if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
    return 'http://localhost:8000/api/v1'
  } else {
    return API_URL
  }
}

const isObject = (objValue) => {
  return objValue && typeof objValue === 'object' && objValue.constructor === Object
}

const getNiceError = (errors) => {
  // Errors come back in many forms, let's get the TLDR
  if (isObject(errors)) {
    if (Object.prototype.hasOwnProperty.call(errors, 'detail')) {
      return errors.detail
    }
    return errors[Object.keys(errors)[0]].toString()
  }
  return errors
}

let http = axios.create({
  withCredentials: true,
  // xsrfCookieName: 'csrftoken',
  // xsrfHeaderName: 'X-CSRFToken',
  baseURL: getApiUrl(),
  headers: {
    'Content-type': 'application/json',
  },
  onUploadProgress: (progressEvent) =>
    store.dispatch(
      'uploadProgress/setProgress',
      parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100)),
    ),
})

http.interceptors.request.use((req) => {
  // console.log(`${req.method} ${req.url}`)
  if (sessionStorage.getItem('accessToken')) {
    req.headers.authorization = `Bearer ${sessionStorage.getItem('access_token')}`
  }

  // Add X-CSRFToken to the headers
  req.headers['X-CSRFToken'] = getCsrfToken()

  return req
})

http.interceptors.response.use(
  (res) => {
    // API response is {"code": 2xx, "status": "OK", "data": {}}
    return res.data.data
  },
  (err) => {
    if (!err.response) {
      let ctx = {
        text: 'Please check your internet connection.',
        color: 'error',
      }
      store.dispatch('toastDialog/show', ctx, { root: true })
      return Promise.reject(err)
    }

    // API response is {"code": 4xx, "status": "BAD_REQUEST", "errors": {}}
    const data = err.response.data

    let errors = err.response.statusText
    if (err.response.status === 401) {
      AuthService.refreshToken()
        .then((data) => {
          if (data.access) {
            sessionStorage.setItem('access_token', data.access)
            sessionStorage.removeItem('refresh_token')
          }
        })
        .catch((error) => {
          console.log(error)
          console.log('TOKEN REFRESH FAILED...LOGOUT')

          store.dispatch('account/logout')
        })
    } else {
      // redirect to login
    }

    if (data && data.errors) {
      errors = getNiceError(data.errors)
      // store.dispatch('alert/error', errors)

      let ctx = {
        text: errors,
        color: 'error',
      }
      store.dispatch('toastDialog/show', ctx, { root: true })
    }
    return Promise.reject(errors)
  },
)

export default http
