<template>
  <v-dialog v-model="dialog" max-width="50%">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="secondary" v-bind="attrs" v-on="on" :disabled="loading">
        <v-icon left>mdi-account-plus</v-icon> Assign User
      </v-btn>
    </template>

    <v-card>
      <v-toolbar dark flat dense color="primary">
        <v-toolbar-title><v-icon left>mdi-account-plus</v-icon> Assign Users</v-toolbar-title>
      </v-toolbar>

      <v-card-text class="pa-4">
        <v-autocomplete
          v-model="selected"
          :items="items"
          :loading="loading"
          :item-text="label"
          prepend-inner-icon="mdi-account-search-outline"
          label="Search Users..."
          outlined
          hide-details
          hide-no-data
          small-chips
          deletable-chips
          multiple
          hide-selected
          return-object
        >
          <template slot="item" slot-scope="{ item }">
            <v-list-item-avatar color="secondary">
              <span v-if="item.initials" class="text--white">{{ item.initials }}</span>
              <v-icon v-else>mdi-account</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-text="item.full_name"></v-list-item-title>
              <v-list-item-subtitle v-text="item.email"></v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-icon color="secondary">mdi-arrow-right</v-icon>
            </v-list-item-action>
          </template>
        </v-autocomplete>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="hide">Cancel</v-btn>
        <v-btn text @click="submit" color="primary" :disabled="loading">Assign Users</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    loading: { type: Boolean, default: false },
    items: { type: Array },
    // defaultSelected: { type: Array },
    // icon: {type: String, default: "mdi-help-circle"},
    // title: { type:String, default: "Title Goes Here"}
    // searchIcon: { type: String, default: 'mdi-magnify' },
    // btnLabel: { type: String, default: 'Label Here' },
    // actionLabel: { type: String, default: 'Label Here' },
  },
  data: () => ({
    dialog: false,
    selected: [],
  }),
  mounted() {
    // this.selected = this.defaultSelected
  },
  methods: {
    hide() {
      this.dialog = false
    },
    submit() {
      this.$emit('selected', this.selected)
      this.dialog = false
    },
    label(item) {
      if (item.name) {
        return item.name
      }
      return item.email
    },
  },
}
</script>

<style scoped>
.radius {
  border-radius: 7px;
}
</style>
