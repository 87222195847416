<template>
  <v-app>
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-12 mb-4">
              <v-toolbar dark flat color="primary">
                <v-img src="@/assets/xstudios-logo-white.svg" contain max-height="25"></v-img>
              </v-toolbar>
              <v-form ref="form">
                <v-card-text>
                  <Toast />

                  <v-text-field
                    prepend-inner-icon="mdi-email"
                    v-model="email"
                    name="email"
                    label="Email"
                    type="text"
                    :rules="[rules.required]"
                    required
                    autocomplete="on"
                    @keyup.enter.prevent="submit()"
                  ></v-text-field>

                  <v-text-field
                    prepend-inner-icon="mdi-lock"
                    v-model="password"
                    name="password"
                    label="Password"
                    :type="showPass ? 'text' : 'password'"
                    :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPass = !showPass"
                    :rules="[rules.required]"
                    @keyup.enter.prevent="submit()"
                  ></v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="submit" color="primary" block x-large> Login </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
            <!-- <div class="text-center caption">
              Don't have an account? <router-link :to="{ name: 'Register' }">Create an account</router-link>
            </div> -->
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { Toast } from '@/spaday/components'

export default {
  name: 'Login',
  components: {
    Toast,
  },
  data: () => ({
    showPass: false,
    email: '',
    password: '',
    rules: {
      required: (value) => !!value || 'Required.',
    },
  }),
  computed: {
    ...mapState('account', ['status', 'user']),
  },
  mounted() {
    // if logged in already, kick back to dashboard
    if (this.status.loggedIn && this.user) {
      this.$router.push({ name: 'Dashboard', params: {} })
    }
  },
  methods: {
    ...mapActions('account', ['login']),
    submit() {
      if (!this.$refs.form.validate()) return

      const { email, password } = this
      if (email && password) {
        this.login({ email, password })
      }
    },
    clear() {
      this.$refs.form.reset()
    },
  },
}
</script>
