const state = {
  type: null,
  message: null,
}

const actions = {
  success({ commit }, message) {
    commit('SUCCESS', message)
  },

  error({ commit }, message) {
    commit('ERROR', message)
  },

  info({ commit }, message) {
    commit('INFO', message)
  },

  warning({ commit }, message) {
    commit('WARNING', message)
  },

  clear({ commit }) {
    console.log('alert/clear')
    commit('CLEAR')
  },
}

const mutations = {
  SUCCESS(state, message) {
    state.type = 'success'
    state.message = message
  },

  ERROR(state, message) {
    state.type = 'error'
    state.message = message
  },

  INFO(state, message) {
    state.type = 'info'
    state.message = message
  },

  WARNING(state, message) {
    state.type = 'warning'
    state.message = message
  },

  CLEAR(state) {
    state.type = null
    state.message = null
  },
}

export const alert = {
  namespaced: true,
  state,
  actions,
  mutations,
}
