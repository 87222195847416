<template>
  <v-row class="pa-3">
    <v-text-field
      v-model="search"
      prepend-inner-icon="mdi-magnify"
      :label="label"
      outlined
      clearable
      dense
      @keydown.enter.prevent="submit(search)"
      @click:clear="submit('')"
      hint="Hint: Submit your search quickly by using the Enter key."
    ></v-text-field
    ><v-btn depressed class="ml-2" @click="submit(search)"> Search </v-btn>
  </v-row>
</template>

<script>
export default {
  name: 'DataTableSearch',
  props: {
    label: { type: String, default: 'Search...' },
  },
  data: () => ({
    search: '',
  }),
  methods: {
    submit(value) {
      this.$emit('search', value)
    },
  },
}
</script>
